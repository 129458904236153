import { Button, Link, Typography } from "@mui/material";
import { useLinkAccount, usePrivy } from "@privy-io/react-auth";
import { useDispatch } from "react-redux";

import { updateSocialData } from "src/services/api";
import { setCurrentUserWalletAddress } from "src/slices/authSlice";
import { addProfileData } from "src/slices/profileSlice";
import { setSnackbarFeedback } from "src/slices/snackbarFeedbackSlice";

import FarcasterIcon from "src/assets/farcaster.svg?react";
import XIcon from "src/assets/x.svg?react";
import { TWITTER_BASE_URL, WARPCAST_BASE_URL } from "src/constants";
import { SocialNetworkType } from "src/types/api-interfaces";

export default function SocialNetworkLink({
    username,
    socialNetworkType,
    children = username ? `@${username}` : null,
    showLogo = false,
    allowUnlink = false,
    ...props
}) {
    const { user, unlinkTwitter, unlinkFarcaster } = usePrivy();
    const dispatch = useDispatch();
    const socialNetworkLink = `${
        socialNetworkType === SocialNetworkType.FARCASTER
            ? WARPCAST_BASE_URL
            : TWITTER_BASE_URL
    }/${username}`;
    const logo =
        socialNetworkType === SocialNetworkType.FARCASTER ? (
            <FarcasterIcon />
        ) : (
            <XIcon />
        );

    const { linkTwitter, linkFarcaster } = useLinkAccount({
        onSuccess: async (userAfterSuccess) => {
            const profilePayload = await updateSocialData();
            dispatch(setCurrentUserWalletAddress(profilePayload.walletAddress));
            dispatch(
                addProfileData({
                    walletAddress: profilePayload.walletAddress,
                    profile: profilePayload,
                }),
            );
        },
        onError: (error) => {
            console.error(error);
            if (error !== "exited_link_flow") {
                dispatch(
                    setSnackbarFeedback({
                        type: "error",
                        message: "Could not link new social network",
                    }),
                );
            }
        },
    });

    if (username) {
        return (
            <>
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={socialNetworkLink}
                    {...props}
                    sx={{
                        display: "inline-flex",
                        gap: 0.5,
                        textDecoration: "none",
                        color: "inherit",
                        alignItems: "center",
                        fontWeight: 500,
                        ...props.sx,
                    }}
                >
                    {showLogo && logo}
                    {children}
                </Link>
                {allowUnlink && import.meta.env.VITE_SENTRY_ENV === "dev" && (
                    <Button
                        onClick={async () => {
                            if (
                                socialNetworkType ===
                                SocialNetworkType.FARCASTER
                            ) {
                                await unlinkFarcaster(user.farcaster.fid);
                            } else {
                                await unlinkTwitter(user.twitter.subject);
                            }

                            try {
                                const profilePayload = await updateSocialData();
                                dispatch(
                                    setCurrentUserWalletAddress(
                                        profilePayload.walletAddress,
                                    ),
                                );
                                dispatch(
                                    addProfileData({
                                        walletAddress:
                                            profilePayload.walletAddress,
                                        profile: profilePayload,
                                    }),
                                );
                            } catch (e) {
                                console.error(e);
                            }
                        }}
                    >
                        Unlink
                    </Button>
                )}
            </>
        );
    } else {
        return (
            <Button
                variant="outline"
                {...props}
                sx={{
                    display: "inline-flex",
                    color: "inherit",
                    ...props.sx,
                }}
                onClick={() => {
                    if (socialNetworkType === SocialNetworkType.FARCASTER) {
                        linkFarcaster();
                    } else {
                        linkTwitter();
                    }
                }}
            >
                {showLogo && <>{logo}&nbsp;</>}
                <Typography
                    sx={{
                        textDecoration: "underline",
                    }}
                >
                    Link{" "}
                    {socialNetworkType === SocialNetworkType.FARCASTER
                        ? "Farcaster"
                        : "X"}
                </Typography>
            </Button>
        );
    }
}
