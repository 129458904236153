import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "src/constants";

export default function SubscribeButton({
    topicId,
    text = "Join the queue now",
    color = "white",
    ...props
}) {
    const navigate = useNavigate();

    return (
        <Button
            className="join-queue-button"
            variant="contained"
            onClick={() => navigate(ROUTES.JOIN)}
            size="small"
            color={color}
            fullWidth
            {...props}
        >
            {text}
        </Button>
    );
}
