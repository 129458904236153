import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

import useCopyToClipboard from "src/hooks/useCopyToClipboard";
import { selectCurrentUserProfile } from "src/slices/profileSlice";

import { SHINE_EFFECT_STYLE } from "src/theme";

export default function ReferralBanner({ onClose }) {
    const theme = useTheme();
    const copyToClipboard = useCopyToClipboard();
    const currentUserProfile = useSelector(selectCurrentUserProfile);
    return (
        <Stack bgcolor="primary.main">
            <Stack
                direction="row"
                gap={1}
                p="1rem 1rem 0.25rem 1rem"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="body2"
                    color="white.main"
                    fontWeight={700}
                    sx={SHINE_EFFECT_STYLE}
                >
                    Refer a friend and earn 1% of their revenue
                </Typography>
                <CloseIcon
                    fontSize="small"
                    sx={{
                        color: theme.palette.background.dark,
                        cursor: "pointer",
                    }}
                    onClick={onClose}
                />
            </Stack>
            <Button
                sx={{
                    p: "0.25rem 1rem 1rem 1rem",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    minHeight: "fit-content",
                    ...SHINE_EFFECT_STYLE,
                }}
                onClick={() =>
                    copyToClipboard({
                        contentToCopy: currentUserProfile.referralLink,
                        successMessage: "Invite link copied to clipboard",
                        errorMessage:
                            "Could not get your invite link, sorry. Please try again",
                    })
                }
                endIcon={
                    <ContentCopyIcon
                        sx={{
                            fontSize: "15px!important",
                            color: "white.main",
                        }}
                    />
                }
            >
                <Typography
                    variant="body2"
                    color="white.main"
                    sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                >
                    Copy your invite link
                </Typography>
            </Button>
        </Stack>
    );
}
