import { useTheme } from "@emotion/react";
import { usePrivy } from "@privy-io/react-auth";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import useCurrentPrivyAccount from "src/hooks/useCurrentPrivyAccount";
import { setTourCompleted } from "src/services/api";
import {
    selectCurrentUserWalletAddress,
    selectIsConnected,
} from "src/slices/authSlice";
import {
    appendMessages,
    resetFeedsState,
    setMessage,
    setMessages,
} from "src/slices/feedsSlice";
import {
    getCurrentUserProfile,
    removeProfileData,
    selectCurrentUserProfile,
} from "src/slices/profileSlice";
import {
    resetSubscriptionsState,
    setCurrentUserSubscription,
    setFeedSubscriptions,
} from "src/slices/subscriptionsSlice";
import {
    resetTopicsState,
    setTopic,
    setTopicPublisherStatus,
} from "src/slices/topicsSlice";

import {
    DEFAULT_PROFILE,
    DEMO_TOUR,
    LocalStorageKeys,
    ROUTES,
    TLP_REVEALED_MESSAGE,
} from "src/constants";
import { MessageRevealedContext, TLPWorkersContext } from "src/contexts";
import { getStorageValue, setStorageValue } from "src/localStorage";
import store from "src/store";

const defaultMurMurTourState = {
    run: false,
    stepIndex: 0,
    steps: [],
    isTourActive: false,
};

export const MurmurTourContext = createContext({
    murmurTourState: defaultMurMurTourState,
    setMurmurTourState: () => {},
});

export function MurmurTourProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );
    const isConnected = useSelector(selectIsConnected);
    const { startTLP, clearTLP, pauseTLP } = useContext(TLPWorkersContext);
    const { setRevealedMessage } = useContext(MessageRevealedContext);

    const [murmurTourState, setMurmurTourState] = useState(
        defaultMurMurTourState,
    );
    const { run, stepIndex, steps } = murmurTourState;
    const { ready, authenticated, user } = usePrivy();
    const account = useCurrentPrivyAccount();
    const privyUserIsFullyAuthenticated = Boolean(
        ready && authenticated && user?.wallet,
    );

    const currentUserProfile = useSelector(selectCurrentUserProfile);

    const tourShouldRun =
        account?.address &&
        privyUserIsFullyAuthenticated &&
        isConnected &&
        location.pathname !== ROUTES.START &&
        !currentUserProfile?.tourCompleted &&
        steps.length > 0;

    const updateMurmurTourState = useCallback((newValues) => {
        setMurmurTourState((oldValues) => ({ ...oldValues, ...newValues }));
    }, []);

    // Tour triggering
    useEffect(() => {
        if (tourShouldRun) {
            navigate(ROUTES.HOME);
            updateMurmurTourState({ run: true, isTourActive: true });
        }
    }, [tourShouldRun, navigate, updateMurmurTourState]);

    const createMurmurDemoData = useCallback(() => {
        dispatch(setTopic(DEMO_TOUR.TOPIC));
        dispatch(
            setFeedSubscriptions({
                topicId: DEMO_TOUR.TOPIC.id,
                subscriptions: DEMO_TOUR.SUBSCRIBERS,
                totalNumberOfSubscriptions: DEMO_TOUR.SUBSCRIBERS.length,
            }),
        );
        dispatch(
            setMessages({
                topicId: DEMO_TOUR.TOPIC.id,
                messages: DEMO_TOUR.MESSAGES,
                totalNumberOfMessages: DEMO_TOUR.MESSAGES.length,
            }),
        );
        dispatch(
            setTopicPublisherStatus({
                accountAddress: DEFAULT_PROFILE.walletAddress,
                isTopicPublisher: true,
                topicId: DEMO_TOUR.TOPIC.id,
                score: 1.0,
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (tourShouldRun) {
            createMurmurDemoData();
        }
    }, [tourShouldRun, createMurmurDemoData]);

    const removeIncomingMessageFromLocalStorage = useCallback(() => {
        const localMessages = getStorageValue(
            LocalStorageKeys.MESSAGES_MAP,
            {},
            currentUserWalletAddress,
        );
        localMessages[DEMO_TOUR.INCOMING_MESSAGE.messageIdentifier] = undefined;
        setStorageValue(
            LocalStorageKeys.MESSAGES_MAP,
            localMessages,
            currentUserWalletAddress,
        );
    }, [currentUserWalletAddress]);

    const clearMurmurTour = useCallback(async () => {
        updateMurmurTourState({
            run: false,
        });
        // clear redux
        dispatch(resetSubscriptionsState());
        dispatch(resetFeedsState());
        // Clear fake profiles
        [
            DEFAULT_PROFILE.walletAddress,
            ...DEMO_TOUR.SUBSCRIBERS.map((subscriber) => subscriber.subscriber),
        ].forEach((address) => {
            dispatch(removeProfileData({ walletAddress: address }));
        });
        dispatch(resetTopicsState());
        removeIncomingMessageFromLocalStorage();
        try {
            await setTourCompleted();
        } catch (error) {
            console.error("Failed to update tour status:", error);
        }
        await getCurrentUserProfile()(dispatch, store.getState);
        updateMurmurTourState({
            isTourActive: false,
            stepIndex: 0,
        });
        navigate(ROUTES.HOME);
    }, [
        dispatch,
        navigate,
        removeIncomingMessageFromLocalStorage,
        updateMurmurTourState,
    ]);

    const restartTour = useCallback(() => {
        createMurmurDemoData();
        navigate(ROUTES.HOME);
        updateMurmurTourState({
            run: true,
            isTourActive: true,
            stepIndex: 0,
        });
    }, [createMurmurDemoData, navigate, updateMurmurTourState]);

    useEffect(() => {
        updateMurmurTourState({
            run: false,
            steps: [
                {
                    target: "body",
                    content:
                        "Welcome to Murmur, your dedicated space for sharing and accessing untapped information. 🤫🌐",
                    placement: "center",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
                {
                    target: ".link-to-profile",
                    content:
                        "First thing to do to understand how Murmur works is to access your profile. 👤 Your profile chip shows the current balance of your dedicated Murmur wallet.",
                    placement: "bottom",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
                {
                    target: ".profile-links",
                    content:
                        "Check the Links section of your profile for useful info: learn about Murmur, access help, and find your private key 🔑",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.PROFILE,
                    },
                },
                {
                    target: ".fund-wallet-button",
                    content:
                        "As a Web3 app, you'll need a small amount of funds for transactions (e.g., 0.001 ETH) to use Murmur effectively. 💵",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.PROFILE,
                    },
                },
                {
                    target: ".invite-link",
                    content:
                        "Here you can find your invite link to share and earn rewards from referrals 🔗",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.PROFILE,
                    },
                },
                {
                    target: ".first-message-progress",
                    content:
                        "A public post is available for everyone to see on Murmur. It was first shared exclusively with the queue members.",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
                {
                    target: ".first-message-eth",
                    content:
                        "This shows the amount of ETH received for the post by the hunter 💰",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
                {
                    target: ".hunter-avatar",
                    content:
                        "Each hunter receives a grade based on the quality of their posts. 📊",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
                {
                    target: ".create-post-button",
                    content: "Simply click here to create a new post 📝",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
                {
                    target: "body",
                    content:
                        "Write the content of your post, with links and images, and press send. 📤",
                    disableBeacon: true,
                    placement: "center",
                    data: {
                        route: ROUTES.CREATE_MESSAGE,
                        actionAfter: () => {
                            dispatch(
                                setFeedSubscriptions({
                                    topicId: DEMO_TOUR.TOPIC.id,
                                    subscriptions: DEMO_TOUR.SUBSCRIBERS,
                                    totalNumberOfSubscriptions:
                                        DEMO_TOUR.SUBSCRIBERS.length,
                                }),
                            );
                        },
                    },
                },
                {
                    target: ".first-queue-member",
                    content:
                        "Your post will first be sent to the first member of the queue for payment and decryption. 🙋",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.FEED_SUBSCRIBERS,
                    },
                },
                {
                    target: ".join-queue-button",
                    content:
                        "Want to access alpha before it’s public? Join the queue! 🚀",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                        actionAfter: () => {
                            // Add user to subs
                            dispatch(
                                setFeedSubscriptions({
                                    topicId: DEMO_TOUR.TOPIC.id,
                                    subscriptions: [
                                        ...DEMO_TOUR.SUBSCRIBERS,
                                        {
                                            burningRate: 400000000000000,
                                            subscriber:
                                                currentUserWalletAddress,
                                        },
                                    ],
                                    totalNumberOfSubscriptions:
                                        DEMO_TOUR.SUBSCRIBERS.length + 1,
                                }),
                            );
                            dispatch(
                                setCurrentUserSubscription({
                                    topicId: DEMO_TOUR.TOPIC.id,
                                    subscription: {
                                        burningRate: 400000000000000,
                                        subscriber: currentUserWalletAddress,
                                    },
                                }),
                            );
                            // Add incoming post
                            dispatch(
                                appendMessages({
                                    topicId: Number(DEMO_TOUR.TOPIC.id),
                                    messages: [DEMO_TOUR.INCOMING_MESSAGE],
                                    totalNumberOfMessages:
                                        DEMO_TOUR.MESSAGES.length + 1,
                                    prepend: true,
                                }),
                            );
                            removeIncomingMessageFromLocalStorage();
                        },
                    },
                },
                {
                    target: ".incoming-post",
                    content:
                        "As a queue member, you have the opportunity to discover posts before everyone else by paying the hunter a fee that fits your budget. ⏱️",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                        actionAfter: () => {
                            // Set the incoming post as post user has paid for
                            setStorageValue(
                                LocalStorageKeys.MESSAGES_MAP,
                                {
                                    [DEMO_TOUR.INCOMING_MESSAGE
                                        .messageIdentifier]:
                                        "Polymarket? More like Poly-money-maker! Who knew predicting the future could be this addictive and potentially profitable? Excuse me while I go bet my life savings on whether aliens will visit Earth next Tuesday. Stay informed with https://x.com/polymarket",
                                },
                                currentUserWalletAddress,
                            );
                            dispatch(
                                setMessage({
                                    ...DEMO_TOUR.INCOMING_MESSAGE,
                                    topic: DEMO_TOUR.TOPIC,
                                    encryptedKey: {
                                        encryptedKey: "encryptedKey",
                                    },
                                    encryptedMessage: "encryptedMessage",
                                    voteClosed: false,
                                    votes: {
                                        downvotes: 1,
                                        totalVotes: 5,
                                        upvotes: 4,
                                    },
                                    userVote: 1,
                                }),
                            );
                        },
                        prevActionAfter: () => {
                            // Remove user from subs
                            dispatch(
                                setFeedSubscriptions({
                                    topicId: DEMO_TOUR.TOPIC.id,
                                    subscriptions: DEMO_TOUR.SUBSCRIBERS,
                                    totalNumberOfSubscriptions:
                                        DEMO_TOUR.SUBSCRIBERS.length,
                                }),
                            );
                            dispatch(
                                setCurrentUserSubscription({
                                    topicId: DEMO_TOUR.TOPIC.id,
                                    subscription: null,
                                }),
                            );
                            // Remove incoming message
                            dispatch(
                                setMessages({
                                    topicId: DEMO_TOUR.TOPIC.id,
                                    messages: DEMO_TOUR.MESSAGES,
                                    totalNumberOfMessages:
                                        DEMO_TOUR.MESSAGES.length,
                                }),
                            );
                        },
                    },
                },
                {
                    target: ".votes",
                    content:
                        "Don't forget to vote on the posts you paid for, that's what impacts the hunter's grade and shows other users the quality of the hunter's posts! 🗳️",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.MESSAGE(
                            DEMO_TOUR.INCOMING_MESSAGE.messageIdentifier,
                        ),
                        actionAfter: () => {
                            // Add message ready to be revealed
                            dispatch(
                                appendMessages({
                                    topicId: Number(DEMO_TOUR.TOPIC.id),
                                    messages: [DEMO_TOUR.READY_TO_REVEAL],
                                    totalNumberOfMessages:
                                        DEMO_TOUR.MESSAGES.length + 1,
                                    prepend: true,
                                }),
                            );
                            // Prepare a clean state for the next step
                            clearTLP(
                                DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                            );
                            setRevealedMessage(null);
                        },
                        prevActionAfter: () => {
                            dispatch(
                                setMessage({
                                    ...DEMO_TOUR.INCOMING_MESSAGE,
                                    topic: DEMO_TOUR.TOPIC,
                                }),
                            );
                            removeIncomingMessageFromLocalStorage();
                        },
                    },
                },
                {
                    target: ".ready-to-reveal-post",
                    content:
                        "Posts start off visible only to queue members. Once they’re ready, any Murmur member can make them public with just a click and a bit of effort",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                        actionAfter: () => {
                            startTLP({
                                messageIdentifier:
                                    DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                                tlpData: DEMO_TOUR.READY_TO_REVEAL.tlpData,
                                onReveal: () => {
                                    const revealedMessage = {
                                        ...DEMO_TOUR.READY_TO_REVEAL,
                                        revealedAt: new Date().toISOString(),
                                        revealedData: TLP_REVEALED_MESSAGE,
                                        topic: DEMO_TOUR.TOPIC,
                                    };
                                    // Open modal (and stop TLP at the same time)
                                    setRevealedMessage(revealedMessage);
                                    // Go to next step automatically if the TLP is done
                                    setMurmurTourState((oldValues) => ({
                                        ...oldValues,
                                        stepIndex: oldValues.stepIndex + 1,
                                    }));
                                },
                                onError: () => {
                                    console.error(
                                        "Error revealing demo message!!!",
                                    );
                                },
                            });
                        },
                    },
                },
                {
                    target: ".ready-to-reveal-post",
                    content:
                        "Revealing a post actually resolves what we call a Time-lock puzzle. This is a cryptographic puzzle that needs to be solved to get the post content and reveal it to everyone. 🔒",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                        prevActionAfter: () => {
                            // Clear TLP that might be running
                            try {
                                pauseTLP(
                                    DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                                );
                            } catch (e) {
                                console.warn("Couldn't stop TLP resolution", e);
                            }
                            clearTLP(
                                DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                            );

                            setRevealedMessage(null);
                        },
                        actionAfter: () => {
                            // Put to revealed state
                            const revealedMessage = {
                                ...DEMO_TOUR.READY_TO_REVEAL,
                                revealedAt: new Date().toISOString(),
                                revealedData: TLP_REVEALED_MESSAGE,
                                topic: DEMO_TOUR.TOPIC,
                            };
                            // To avoid having the "Nice try!" modal, we clear the TLP before setting the revealed message
                            try {
                                pauseTLP(
                                    DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                                );
                            } catch (e) {
                                console.warn("Couldn't stop TLP resolution", e);
                            }
                            clearTLP(
                                DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                            );
                            // Open modal (and stop TLP at the same time)
                            setRevealedMessage(revealedMessage);
                            // Update message in redux
                            dispatch(setMessage(revealedMessage));
                        },
                    },
                },
                {
                    target: ".congrats-modal",
                    content:
                        "By revealing the post, you earn a reward in ETH depending on the revenue the post generated to the hunter. 🎉",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                        prevActionAfter: () => {
                            // Close modal if opened
                            setRevealedMessage(null);
                            // Reset message to unrevealed state
                            dispatch(
                                setMessage({
                                    ...DEMO_TOUR.READY_TO_REVEAL,
                                    topic: DEMO_TOUR.TOPIC,
                                }),
                            );
                            // Clear TLP that might be leftover
                            clearTLP(
                                DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                            );
                            // Start TLP again
                            startTLP({
                                messageIdentifier:
                                    DEMO_TOUR.READY_TO_REVEAL.messageIdentifier,
                                tlpData: DEMO_TOUR.READY_TO_REVEAL.tlpData,
                                onReveal: () => {
                                    const revealedMessage = {
                                        ...DEMO_TOUR.READY_TO_REVEAL,
                                        revealedAt: new Date().toISOString(),
                                        revealedData: TLP_REVEALED_MESSAGE,
                                        topic: DEMO_TOUR.TOPIC,
                                    };

                                    // To avoid having the "Nice try!" modal, we clear the TLP before setting the revealed message
                                    clearTLP(
                                        DEMO_TOUR.READY_TO_REVEAL
                                            .messageIdentifier,
                                    );

                                    setRevealedMessage(revealedMessage);
                                    dispatch(setMessage(revealedMessage));
                                },
                                onError: () => {
                                    console.error(
                                        "Error revealing demo message!!!",
                                    );
                                },
                            });
                        },
                        actionAfter: () => {
                            // Close modal
                            setRevealedMessage(null);
                        },
                    },
                },
                {
                    target: ".feed-tab-NOTIFICATIONS",
                    content:
                        "To make sure to not miss a post ready to be revealed, you can turn the notifications on in the Notifications tab 🔔",
                    placement: "top",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.FEED_NOTIFICATIONS,
                        prevWithTimeout: true,
                        prevActionAfter: () => {
                            const revealedMessage = {
                                ...DEMO_TOUR.READY_TO_REVEAL,
                                revealedAt: new Date().toISOString(),
                                revealedData: TLP_REVEALED_MESSAGE,
                                topic: DEMO_TOUR.TOPIC,
                            };
                            // Open modal
                            setRevealedMessage(revealedMessage);
                        },
                    },
                },
                {
                    target: "body",
                    content:
                        "Congratulations! You've completed the tour. 🎉 Let us set everything up so you can start using Murmur right away!",
                    placement: "center",
                    disableBeacon: true,
                    data: {
                        route: ROUTES.HOME,
                    },
                },
            ],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentUserWalletAddress,
        dispatch,
        navigate,
        removeIncomingMessageFromLocalStorage,
        updateMurmurTourState,
    ]);

    const handleCallback = async (data) => {
        const { action, index, step, type, status, size } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            await clearMurmurTour();
        } else {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            const nextStepRoute = steps[nextStepIndex]?.data?.route;
            let withTimeout =
                ACTIONS.PREV === action && step.data?.prevWithTimeout;
            if (step.data) {
                const {
                    actionAfter = undefined,
                    actionBefore = undefined,
                    prevActionAfter = undefined,
                } = step.data;

                if (
                    type === EVENTS.STEP_BEFORE &&
                    action === ACTIONS.NEXT &&
                    actionBefore
                ) {
                    await actionBefore();
                }
                if (
                    type === EVENTS.STEP_AFTER &&
                    action === ACTIONS.NEXT &&
                    actionAfter
                ) {
                    await actionAfter();
                }
                if (
                    type === EVENTS.STEP_AFTER &&
                    ACTIONS.PREV === action &&
                    prevActionAfter
                ) {
                    await prevActionAfter();
                }
            }

            if (
                type === EVENTS.STEP_AFTER &&
                nextStepRoute &&
                nextStepRoute !== location.pathname
            ) {
                withTimeout = true;
                navigate(nextStepRoute);
            }
            if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
                // Update state to advance the tour
                if (nextStepIndex === size) {
                    await clearMurmurTour();
                } else {
                    if (withTimeout) {
                        updateMurmurTourState({
                            run: false,
                        });
                        setTimeout(async () => {
                            updateMurmurTourState({
                                run: true,
                                stepIndex: nextStepIndex,
                            });
                        }, 400);
                    } else {
                        updateMurmurTourState({
                            stepIndex: nextStepIndex,
                        });
                    }
                }
            }
        }
    };

    const murmurTourStateValue = useMemo(
        () => ({
            murmurTourState,
            updateMurmurTourState,
            restartTour,
        }),
        [updateMurmurTourState, murmurTourState, restartTour],
    );

    return (
        <MurmurTourContext.Provider value={murmurTourStateValue}>
            {children}
            {murmurTourState?.isTourActive && (
                <>
                    <Joyride
                        callback={handleCallback}
                        continuous
                        run={run}
                        showProgress
                        showSkipButton
                        disableOverlayClose
                        hideCloseButton
                        stepIndex={stepIndex}
                        steps={steps}
                        locale={{
                            back: "Previous",
                            close: "Close",
                            last: "Start using the app",
                            next: "Next",
                            open: "Open the dialog",
                            skip: "Skip tutorial",
                        }}
                        styles={{
                            // https://github.com/gilbarbara/react-joyride/blob/main/src/styles.ts
                            options: {
                                arrowColor: theme.palette.yellow.main,
                                backgroundColor: theme.palette.yellow.main,
                                overlayColor: "rgba(255, 255, 255, 0.3)",
                                primaryColor: theme.palette.darkHeader.main,
                                textColor: theme.palette.darkHeader.main,
                                zIndex: 2000,
                            },
                            tooltip: {
                                padding: "1rem",
                            },
                            tooltipContent: {
                                padding: 0,
                            },
                            tooltipFooter: {
                                marginTop: "1rem",
                            },
                        }}
                    />
                </>
            )}
        </MurmurTourContext.Provider>
    );
}
