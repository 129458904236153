import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getLatestLottery,
    selectLatestLottery,
} from "src/slices/lotteriesSlice";

import { getLotteryFetchInterval } from "src/utils";

export default function useHomeBanner() {
    const dispatch = useDispatch();
    const [showReferralBanner, setShowReferralBanner] = useState(false);
    const [showLotteryBanner, setShowLotteryBanner] = useState(false);
    const latestLottery = useSelector(selectLatestLottery());

    const dismissedGetReferralLinkCookieName = "dismissed-get-referral-link";
    const dismissedGetReferralLinkCookie = Cookies.get(
        dismissedGetReferralLinkCookieName,
    );

    const dismissedLotteryCookieName = "dismissed-lottery";
    const dismissedLotteryCookie = Cookies.get(dismissedLotteryCookieName);

    const fetchLotteryInterval = getLotteryFetchInterval(latestLottery);

    useEffect(() => {
        dispatch(getLatestLottery());
        const timer = setInterval(async () => {
            dispatch(getLatestLottery());
        }, fetchLotteryInterval);
        return () => clearInterval(timer);
    }, [dispatch, fetchLotteryInterval]);

    useEffect(() => {
        setShowReferralBanner(dismissedGetReferralLinkCookie !== "true");
    }, [dismissedGetReferralLinkCookie]);

    useEffect(() => {
        setShowLotteryBanner(
            latestLottery &&
                `${latestLottery?.id}-${latestLottery?.hasBeenHandled}` !==
                    dismissedLotteryCookie,
        );
    }, [dismissedLotteryCookie, latestLottery]);

    const onCloseReferralBanner = () => {
        Cookies.set(dismissedGetReferralLinkCookieName, true, {
            expires: 7,
        });
        setShowReferralBanner(false);
    };

    const onCloseLotteryBanner = () => {
        Cookies.set(
            dismissedLotteryCookieName,
            `${latestLottery.id}-${latestLottery.hasBeenHandled}`,
            { expires: 7 },
        );
        setShowLotteryBanner(false);
    };

    return {
        showReferralBanner,
        showLotteryBanner,
        onCloseReferralBanner,
        onCloseLotteryBanner,
        latestLottery,
    };
}
