/* global BigInt, */
import InfoIcon from "@mui/icons-material/Info";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { selectCurrentUserWalletAddress } from "src/slices/authSlice";
import { selectContractSettings } from "src/slices/contractSettingsSlice";
import { selectSortedFeedSubscriptions } from "src/slices/subscriptionsSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";

import { CURRENCY_SYMBOL } from "src/constants";
import { getEffectiveBurningRateForSubscriber } from "src/utils";

export default function MaxBidAndCurrentEffectivePrice({
    burningRateInWei,
    topicId,
}) {
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );
    const { burningRateStep } = useSelector(selectContractSettings);
    const subscriptions = useSelector(selectSortedFeedSubscriptions(topicId));

    let effectiveBurningRate = "0";
    if (currentUserWalletAddress && burningRateStep && subscriptions) {
        const effectiveRateAsBigInt = getEffectiveBurningRateForSubscriber(
            currentUserWalletAddress,
            subscriptions,
            burningRateStep,
            burningRateInWei ? BigInt(burningRateInWei) : undefined,
        );
        effectiveBurningRate = effectiveRateAsBigInt.toString();
    }

    return (
        <Stack>
            <Stack direction="row" alignItems="center" gap={1} py={1}>
                <Tooltip
                    title="This is not the amount you necessarily pay per message, this is the max amount you are willing to pay to maintain your spot in the queue."
                    onClick={(e) => e.stopPropagation()}
                >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography color="secondary">
                            Max amount you can bid per post
                        </Typography>
                        <InfoIcon color="secondary" sx={{ fontSize: 14 }} />
                    </Stack>
                </Tooltip>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    flex={1}
                >
                    {burningRateInWei !== null &&
                        burningRateInWei !== undefined && (
                            <CurrencyDisplay
                                amount={burningRateInWei}
                                currency={CURRENCY_SYMBOL.ETH}
                                convertFrom={CURRENCY_SYMBOL.WEI}
                                noWrap
                            />
                        )}
                </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" gap={1} py={1}>
                <Tooltip
                    title="This amount will always be inferior or equal to the max bid per message and will be optimized by the protocol so that you never over-pay, allowing you to pay the smallest amount necessary for you to maintain your spot."
                    onClick={(e) => e.stopPropagation()}
                >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography color="secondary">
                            Current amount you will pay per post
                        </Typography>
                        <InfoIcon color="secondary" sx={{ fontSize: 14 }} />
                    </Stack>
                </Tooltip>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    flex={1}
                >
                    {effectiveBurningRate !== null &&
                        effectiveBurningRate !== undefined && (
                            <CurrencyDisplay
                                amount={effectiveBurningRate}
                                currency={CURRENCY_SYMBOL.ETH}
                                convertFrom={CURRENCY_SYMBOL.WEI}
                                noWrap
                            />
                        )}
                </Stack>
            </Stack>
            <Divider />
        </Stack>
    );
}
