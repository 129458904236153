import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import LotteryInfoModal from "src/components/LotteryInfoModal";
import LotteryPointsModal from "src/components/LotteryPointsModal";

import { CURRENCY_SYMBOL } from "src/constants";
import { formatRemainingTime } from "src/utils";

const LOTTERY_STATES = {
    UPCOMING: 1,
    CURRENT: 2,
    FINISHED: 3,
};

export default function LotteryBanner({ latestLottery, onClose }) {
    const theme = useTheme();
    const [lotteryPointsModalOpen, setLotteryPointsModalOpen] = useState(false);
    const [lotteryInfoModalOpen, setLotteryInfoModalOpen] = useState(false);

    let lotteryState;
    if (latestLottery?.hasBeenHandled) {
        lotteryState = LOTTERY_STATES.FINISHED;
    } else if (
        latestLottery?.startDate &&
        new Date(latestLottery?.startDate) > Date.now()
    ) {
        lotteryState = LOTTERY_STATES.UPCOMING;
    } else {
        lotteryState = LOTTERY_STATES.CURRENT;
    }

    const lotteryBoxTitle = {
        // Arrow functions delays computation until needed, ensuring correct values in the function calls.
        [LOTTERY_STATES.FINISHED]: () => "Previous lottery has ended",
        [LOTTERY_STATES.CURRENT]: () =>
            `Lottery ends ${formatRemainingTime({ dateString: latestLottery.endDate, prefix: "in" })}`,
        [LOTTERY_STATES.UPCOMING]: () =>
            `Next lottery will start ${formatRemainingTime({ dateString: latestLottery.startDate, prefix: "in" })}`,
    };

    const lotteryBoxLegend = {
        [LOTTERY_STATES.FINISHED]: "See the rewards",
        [LOTTERY_STATES.CURRENT]: "Collect coupons and win up to ",
        [LOTTERY_STATES.UPCOMING]: "Get ready to collect coupons to win up to ",
    };

    return (
        <>
            <Stack bgcolor="yellow.main">
                <Stack
                    direction="row"
                    gap={1}
                    p="1rem 1rem 0.25rem 1rem"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="body2"
                        color="background.dark"
                        fontWeight={700}
                    >
                        {lotteryBoxTitle[lotteryState]()}
                    </Typography>
                    <CloseIcon
                        fontSize="small"
                        sx={{
                            color: theme.palette.background.dark,
                            cursor: "pointer",
                        }}
                        onClick={onClose}
                    />
                </Stack>
                <Button
                    sx={{
                        p: "0.25rem 1rem 1rem 1rem",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        minHeight: "fit-content",
                        width: "fit-content",
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                    onClick={() => {
                        if (
                            [
                                LOTTERY_STATES.CURRENT,
                                LOTTERY_STATES.UPCOMING,
                            ].includes(lotteryState)
                        ) {
                            setLotteryInfoModalOpen(true);
                        } else {
                            setLotteryPointsModalOpen(true);
                        }
                    }}
                    endIcon={
                        [
                            LOTTERY_STATES.CURRENT,
                            LOTTERY_STATES.UPCOMING,
                        ].includes(lotteryState) ? (
                            <InfoIcon
                                sx={{
                                    fontSize: "15px!important",
                                    color: "background.dark",
                                }}
                            />
                        ) : undefined
                    }
                >
                    <Typography
                        variant="body2"
                        color="background.dark"
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        {lotteryBoxLegend[lotteryState]}
                        {[
                            LOTTERY_STATES.CURRENT,
                            LOTTERY_STATES.UPCOMING,
                        ].includes(lotteryState) && (
                            <CurrencyDisplay
                                amount={
                                    latestLottery.amountToBeDistributedWei / 2
                                }
                                currency={CURRENCY_SYMBOL.ETH}
                                convertFrom={CURRENCY_SYMBOL.WEI}
                                toFixed={2}
                                sx={{ fontWeight: 800 }}
                            />
                        )}
                    </Typography>
                </Button>
            </Stack>
            <LotteryPointsModal
                open={lotteryPointsModalOpen}
                onClose={() => {
                    setLotteryPointsModalOpen(false);
                }}
            />
            <LotteryInfoModal
                open={lotteryInfoModalOpen}
                onClose={() => {
                    setLotteryInfoModalOpen(false);
                }}
            />
        </>
    );
}
