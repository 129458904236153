import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectIsConnected, selectIsLoggingOut } from "src/slices/authSlice";

import FullscreenCircularProgress from "src/components/FullscreenCircularProgress";

import { ROUTES } from "src/constants";

const PageProtectionWrapper = ({ children, isProtected }) => {
    const isConnected = useSelector(selectIsConnected);
    const isLoggingOut = useSelector(selectIsLoggingOut);
    const location = useLocation();
    const { logout: privyLogout, ready, authenticated, user } = usePrivy();
    const navigate = useNavigate();

    const privyUserIsAuthenticated = Boolean(
        ready && authenticated && user?.wallet,
    );

    useEffect(() => {
        if (!(privyUserIsAuthenticated && isConnected) && isProtected) {
            // if we have no more privy access token, we do a logoutFromAll() (in getPrivyAccessTokenOrLogout) that implies isConnected = false
            // in this case, it's possible that user is still "authenticated" in privy context but we have no more token to validate in backend
            // so in this case we use privyLogout() that will show a warning "Error destroying session"
            // because in the https://auth.privy.io/api/v1/sessions/logout call there is no needed refresh_token value (response error is "Invalid auth token")
            // but we need to do this call else, privy is still connected and we have an error when we try to log in
            const timeout = setTimeout(async () => {
                if (privyUserIsAuthenticated) {
                    await privyLogout();
                }
                navigate(
                    ROUTES.START,
                    location.pathname !== "/profile"
                        ? { state: { from: location.pathname } }
                        : undefined,
                );
            }, 5000);
            return () => {
                clearTimeout(timeout);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        navigate,
        location,
        isProtected,
        privyUserIsAuthenticated,
        isConnected,
    ]);

    if (!isProtected) {
        return children;
    }

    if (!(privyUserIsAuthenticated && isConnected)) {
        if (isLoggingOut) {
            return <FullscreenCircularProgress label="Logging you out" />;
        } else {
            return (
                <FullscreenCircularProgress label="Verifying your account is complete. If not you will be redirected to the sign up flow" />
            );
        }
    }

    return children;
};

export default PageProtectionWrapper;
