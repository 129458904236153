import { Avatar, Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTopicId from "src/hooks/useTopicId";
import {
    getTopicPublisherStatus,
    selectPublisherStatusByTopic,
} from "src/slices/topicsSlice";

import PublisherGrade from "src/components/PublisherGrade";

import { DEMO_TOUR } from "src/constants";
import { getAvatarNameComponentPropsFromString } from "src/utils";

// 0.4 to make it 8px for a size of 20
const BADGE_RATIO = 0.4;

export default function AvatarWithBadge({
    profile,
    avatarSize = 25,
    gradeSize = 12,
    loading = false,
    showBadge = true,
    sx = {},
    onClick,
    ...props
}) {
    const badgeSize = BADGE_RATIO * avatarSize;
    const topicId = useTopicId();

    const dispatch = useDispatch();

    const publisherStatus = useSelector(
        selectPublisherStatusByTopic(topicId, profile?.walletAddress),
    );

    useEffect(() => {
        if (
            topicId &&
            Number(topicId) !== DEMO_TOUR.TOPIC.id &&
            profile?.walletAddress &&
            showBadge &&
            publisherStatus === undefined
        ) {
            dispatch(getTopicPublisherStatus(topicId, profile?.walletAddress));
        }
    }, [dispatch, topicId, profile?.walletAddress, showBadge, publisherStatus]);
    const shouldDisplayBadge = !loading && showBadge && publisherStatus?.score;
    const avatarExtraMargin = `${gradeSize / 4}px!important`;
    return (
        <Box
            sx={{
                position: "relative",
                ...sx,
                mt: shouldDisplayBadge ? avatarExtraMargin : undefined,
                mb: shouldDisplayBadge ? avatarExtraMargin : undefined,
                mr: shouldDisplayBadge ? avatarExtraMargin : undefined,
            }}
            onClick={onClick}
            {...props}
        >
            <Avatar
                alt={profile?.name}
                src={profile?.pictureUrl}
                size={avatarSize}
                {...(profile &&
                !profile.pictureUrl &&
                (profile.name ?? profile.username)
                    ? getAvatarNameComponentPropsFromString(
                          profile.name ?? profile.username,
                      )
                    : {})}
            />
            <Box
                sx={{
                    position: "absolute",
                    lineHeight: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                {loading ? (
                    <CircularProgress size={badgeSize} color="white" />
                ) : null}
                {shouldDisplayBadge && (
                    <Box
                        sx={{
                            width: badgeSize,
                            height: badgeSize,
                            background: "black",
                            borderRadius: avatarSize,
                            position: "relative",
                        }}
                    >
                        <PublisherGrade
                            score={publisherStatus.score}
                            size={gradeSize}
                            sx={{
                                color: "yellow.main",
                                fontSize: badgeSize,
                                position: "absolute",
                                left: -(badgeSize * BADGE_RATIO),
                                top: -(badgeSize * BADGE_RATIO),
                            }}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}
