import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectIsConnected } from "src/slices/authSlice";

import FeedHeaderTitle from "src/components/FeedHeaderTitle";
import FeedSubHeader from "src/components/FeedSubHeader";
import FeedTabs from "src/components/FeedTabs";
import Logo from "src/components/Logo";
import UserBalances from "src/components/UserBalances";

import { FEED_TABS, ROUTES } from "src/constants";
import { MessageStatusContext } from "src/contexts";
import { capitalize } from "src/utils";

export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const body = document.querySelector("body");

    const isConnected = useSelector(selectIsConnected);

    const displayGoBackButton =
        !Object.values(FEED_TABS)
            .map((tab) => tab.path)
            .includes(location.pathname) ||
        location.pathname === ROUTES.PROFILE;

    const {
        messageStatus: { visibility },
    } = useContext(MessageStatusContext);

    let backgroundColor = "background";
    let title = (
        <Box
            sx={{
                alignItems: "flex-start",
                flex: 1,
            }}
        >
            <Button
                onClick={() =>
                    navigate(isConnected ? ROUTES.HOME : ROUTES.START)
                }
                sx={{
                    padding: 0,
                }}
            >
                <Logo />
            </Button>
        </Box>
    );

    let rightItem = (
        <UserBalances
            showCurrentUserAvatar
            backgroundColor={
                displayGoBackButton && location.pathname !== ROUTES.PROFILE
                    ? "background.main"
                    : undefined
            }
        />
    );

    let subTitle = (
        <>
            <FeedSubHeader />
            <FeedTabs />
        </>
    );
    if (displayGoBackButton) {
        subTitle = null;
        if (location.pathname === ROUTES.PROFILE) {
            title = null;
        } else {
            backgroundColor = "darkHeader";
            let headerTitleProps = {};
            if (location.pathname.includes(ROUTES.CREATE_MESSAGE)) {
                const text = `New post`;
                headerTitleProps = { text };
            } else if (location.pathname.includes(ROUTES.PREVIEW_MESSAGE)) {
                const text = "Secret post";
                headerTitleProps = { text };
            } else if (location.pathname.includes(ROUTES.MESSAGE(""))) {
                let text = `${capitalize(visibility)} post`;
                headerTitleProps = { text };
            } else if (location.pathname === ROUTES.CHANGE_SPOT) {
                const text = "Choose your spot";
                headerTitleProps = { text };
            } else if (location.pathname === ROUTES.JOIN) {
                const text = "Join the queue";
                headerTitleProps = { text };
            }
            title = <FeedHeaderTitle {...headerTitleProps} />;
        }
    }
    // Status bar color handling on iOS
    if (location.pathname !== ROUTES.HOME) {
        body.setAttribute(
            "style",
            `background-color: ${theme.palette.primary.dark}`,
        );
    } else {
        body.removeAttribute("style");
    }

    const content = (
        <>
            <Box
                flex={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                pt={3}
                pb={1}
            >
                {displayGoBackButton && (
                    <Button
                        sx={{
                            minWidth: 0,
                            ml: -1,
                            paddingRight: 0,
                        }}
                        color={"white"}
                        onClick={() => {
                            if (!isConnected) {
                                // Post detail page is the only one available not connected
                                navigate(ROUTES.START);
                            } else if (location.state?.from) {
                                // If on the profile page (from is defined only on profile page), go back to where you came from
                                // if we know what that is
                                navigate(location.state.from);
                            } else if (
                                location.pathname === ROUTES.CHANGE_SPOT
                            ) {
                                // From "change spot" page,
                                // go back to the list of subscribers
                                navigate(ROUTES.FEED_SUBSCRIBERS);
                            } else if (
                                location.pathname.includes(
                                    ROUTES.PREVIEW_MESSAGE,
                                )
                            ) {
                                // From "preview post" page before sending post,
                                // go back to create post page
                                navigate(ROUTES.CREATE_MESSAGE);
                            } else {
                                // In doubt, go home
                                navigate(ROUTES.HOME);
                            }
                        }}
                    >
                        <ArrowBackIosIcon />
                    </Button>
                )}
                {title}
                {rightItem}
            </Box>
            {subTitle}
        </>
    );

    return (
        <AppBar
            position={"relative"}
            component="nav"
            color={backgroundColor}
            style={{
                boxShadow: "none",
            }}
        >
            {content}
        </AppBar>
    );
}
