import { Box, Modal, Stack } from "@mui/material";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useBalance from "src/hooks/useBalance";
import { setDealtWithNewWalletInfoOnProfile } from "src/services/api";
import {
    getCurrentUserProfile,
    selectCurrentUserProfile,
} from "src/slices/profileSlice";

import FundWallet from "src/components/FundWallet";

import WalletIcon from "src/assets/wallet_base.svg?react";
import { NoFundsModalContext } from "src/contexts";

export default function NoFundsModal() {
    const dispatch = useDispatch();
    const { noFundsModalOpen, setIsNoFundsModalOpen } =
        useContext(NoFundsModalContext);
    const profile = useSelector(selectCurrentUserProfile);
    const { balance } = useBalance({ address: profile?.walletAddress });

    useEffect(() => {
        (async () => {
            if (
                balance !== 0n &&
                profile &&
                !profile.hasDealtWithNewWalletInfo
            ) {
                await setDealtWithNewWalletInfoOnProfile();
                dispatch(getCurrentUserProfile());
            }
        })();
    }, [balance, dispatch, profile]);

    return (
        <Modal
            open={noFundsModalOpen}
            onClose={(e) => {
                e.stopPropagation();
                setIsNoFundsModalOpen(false);
            }}
        >
            <Stack
                sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "background.main",
                    justifyContent: "center",
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: 600,
                        margin: "auto",
                    }}
                >
                    <Stack alignItems="center" spacing={2}>
                        {!profile?.hasDealtWithNewWalletInfo ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <WalletIcon style={{ fontSize: 60 }} />
                            </Box>
                        ) : null}
                        <FundWallet
                            address={profile?.walletAddress}
                            title={
                                profile?.hasDealtWithNewWalletInfo
                                    ? "You can deposit as little as 0.001 ETH to perform this action"
                                    : "Your wallet has been created and you can deposit as little as 0.001 ETH to start using the app"
                            }
                            subTitle={
                                "Please fund your Base wallet from Base or Ethereum first"
                            }
                            skipLabel="Skip"
                            onSkip={async () => {
                                setIsNoFundsModalOpen(false);
                                if (!profile?.hasDealtWithNewWalletInfo) {
                                    await setDealtWithNewWalletInfoOnProfile();
                                    dispatch(getCurrentUserProfile());
                                }
                            }}
                        />
                    </Stack>
                </Box>
            </Stack>
        </Modal>
    );
}
