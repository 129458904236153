import { TabContext, TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import useHomeBanner from "src/hooks/useHomeBanner";

import LotteryBanner from "src/components/LotteryBanner";
import ReferralBanner from "src/components/ReferralBanner";

import { FEED_TABS } from "src/constants";

export default function FeedTabs() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const {
        showReferralBanner,
        showLotteryBanner,
        onCloseReferralBanner,
        onCloseLotteryBanner,
        latestLottery,
    } = useHomeBanner();

    const tabs = FEED_TABS;
    const activeTabEntry = Object.entries(tabs).find(
        ([key, { path, activePaths }]) =>
            pathname === path || activePaths.includes(pathname),
    );
    let activeTab = Object.keys(tabs)[0];
    if (activeTabEntry) {
        [activeTab] = activeTabEntry;
    }

    return (
        <>
            {showReferralBanner && !showLotteryBanner ? (
                <ReferralBanner onClose={onCloseReferralBanner} />
            ) : null}
            {showLotteryBanner ? (
                <LotteryBanner
                    onClose={onCloseLotteryBanner}
                    latestLottery={latestLottery}
                />
            ) : null}
            <TabContext value={activeTab}>
                <TabList
                    variant="scrollable"
                    allowScrollButtonsMobile
                    sx={{
                        backgroundColor: "background.main",
                        ".MuiTabs-flexContainer": { gap: "5px" },
                        ".MuiTabs-scrollButtons.Mui-disabled": {
                            display: "none",
                        },
                    }}
                >
                    {Object.entries(tabs).map(([key, { label, path }]) => (
                        <Tab
                            key={key}
                            label={label}
                            value={key}
                            onClick={() => navigate(path)}
                            className={`feed-tab-${key}`}
                        />
                    ))}
                </TabList>
            </TabContext>
        </>
    );
}
