import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTopicId from "src/hooks/useTopicId";
import {
    getLatestLottery,
    selectIsLotteryOngoing,
} from "src/slices/lotteriesSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import ShareOnSocialNetworkButton from "src/components/ShareOnSocialNetworkButton";
import StyledModal from "src/components/StyledModal";

import EthereumIcon from "src/assets/ethereum.svg?react";
import {
    CURRENCY_SYMBOL,
    MAX_LOTTERY_PRIZE_DECIMALS,
    ROUTES,
    SOCIAL_NETWORK_SHARE_MESSAGES,
} from "src/constants";
import { MessageRevealedContext } from "src/contexts";

export default function CongratsRevealMessageModal() {
    const dispatch = useDispatch();
    const topicId = useTopicId();
    const theme = useTheme();

    const { revealedMessage, setRevealedMessage } = useContext(
        MessageRevealedContext,
    );
    const isLotteryOngoing = useSelector(selectIsLotteryOngoing());

    useEffect(() => {
        dispatch(getLatestLottery());
    }, [dispatch]);

    if (!Number.isInteger(topicId) || !revealedMessage) {
        // Do not render if we're not on a topic page
        return null;
    }

    const handleClose = () => setRevealedMessage(null);

    let rewards = [];
    if (isLotteryOngoing) {
        rewards.push(
            <>
                {revealedMessage.revealerLotteryPoints ?? 0}
                &nbsp;
                <LocalActivityIcon
                    color="secondary"
                    style={{ verticalAlign: "middle" }}
                />
            </>,
        );
    }
    if (revealedMessage?.revealerRevenueWei) {
        rewards.push(
            <>
                <CurrencyDisplay
                    amount={revealedMessage.revealerRevenueWei || 0}
                    currency={CURRENCY_SYMBOL.ETH}
                    convertFrom={CURRENCY_SYMBOL.WEI}
                    toFixed={
                        revealedMessage.revealerRevenueWei
                            ? MAX_LOTTERY_PRIZE_DECIMALS
                            : 0
                    }
                    showUnit={false}
                />
                <EthereumIcon
                    style={{
                        color: theme.palette.secondary.main,
                        verticalAlign: "middle",
                    }}
                />
            </>,
        );
    }

    return (
        <StyledModal
            open={Boolean(revealedMessage)}
            onClose={handleClose}
            aria-labelledby={"congrats-title"}
            aria-describedby={"congrats-description"}
        >
            <Stack gap={1} className="congrats-modal">
                <Typography id="congrats-title" variant="h6">
                    Congrats!
                </Typography>
                <Box p="0.5rem 0">
                    <Typography id="congrats-description" color="secondary">
                        {rewards.length === 0 ? (
                            "You successfully revealed the post"
                        ) : (
                            <>
                                You earned&nbsp;
                                {rewards.map((reward, index) => (
                                    <React.Fragment key={index}>
                                        {!!index && <>&nbsp;and&nbsp;</>}
                                        {reward}
                                    </React.Fragment>
                                ))}
                                &nbsp;by successfully revealing the post.
                            </>
                        )}
                    </Typography>
                </Box>

                <ShareOnSocialNetworkButton
                    messageFunction={
                        SOCIAL_NETWORK_SHARE_MESSAGES.REVEALED_MESSAGE
                    }
                    messageExtraParams={{
                        postLink: `${import.meta.env.VITE_FRONTEND_HOST}${ROUTES.MESSAGE(revealedMessage.messageIdentifier)}`,
                        ownerUsername: revealedMessage.owner?.username,
                    }}
                />
            </Stack>
        </StyledModal>
    );
}
