import { Box, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { selectLatestLottery } from "src/slices/lotteriesSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";
import LotteryBaseModalContent from "src/components/LotteryBaseModalContent";
import StyledModal from "src/components/StyledModal";
import TopicParticipantRow from "src/components/TopicParticipantRow";

import { CURRENCY_SYMBOL, MAX_LOTTERY_PRIZE_DECIMALS } from "src/constants";

export default function LotteryPointsModal({ open, onClose }) {
    const currentLottery = useSelector(selectLatestLottery());

    return (
        <StyledModal
            open={open}
            aria-labelledby="lottery-points-label"
            aria-describedby="lottery-points-description"
        >
            <LotteryBaseModalContent
                title="Lottery results"
                skipButtonContent="Close"
                onSkipButtonClick={onClose}
            >
                {currentLottery?.winners?.length > 0 ? (
                    <Stack sx={{ mt: 4 }}>
                        {currentLottery?.winners
                            ?.slice()
                            ?.sort((w1, w2) => w1.place - w2.place)
                            ?.map((winner) => {
                                return (
                                    <Box key={winner?.walletAddress}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            gap={1}
                                            py={1}
                                        >
                                            <TopicParticipantRow
                                                sx={{
                                                    backgroundColor: null,
                                                    px: 0,
                                                    ml: -1,
                                                }}
                                                gap={0}
                                                spacing={1}
                                                justifyContent="flex-start"
                                                address={winner.walletAddress}
                                            />
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="flex-end"
                                                flex={1}
                                            >
                                                <CurrencyDisplay
                                                    amount={
                                                        winner.prizeAmountWei
                                                    }
                                                    currency={
                                                        CURRENCY_SYMBOL.ETH
                                                    }
                                                    convertFrom={
                                                        CURRENCY_SYMBOL.WEI
                                                    }
                                                    toFixed={
                                                        MAX_LOTTERY_PRIZE_DECIMALS
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                );
                            })}
                    </Stack>
                ) : (
                    <Typography color="secondary">
                        No winner this time. Better luck next time!
                    </Typography>
                )}
            </LotteryBaseModalContent>
        </StyledModal>
    );
}
