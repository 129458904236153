import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { Hex } from "viem";

import {
    getMessageFromBackend,
    getMessagesFromBackend,
    setMessageReadFromBackend,
} from "src/services/api";
import { getMessageData } from "src/services/murmur";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { TAKE_MESSAGES_AMOUNT } from "src/constants";
import { AppDispatch, RootState } from "src/store";
import { Message, Slowdown } from "src/types/extraTypes";
import { MurmurContractType } from "src/types/murmurContractType";

type LoadingTypes = "messages" | "message";

interface IFeedsState {
    messagesByFeed: Record<
        string,
        {
            identifiers: Hex[];
            totalNumberOfMessages: number;
        }
    >;
    messagesByIdentifier: Record<Hex, Message | null>;
    pendingMessagesByFeedId: Record<
        string,
        { messageIdentifiers: Hex[]; totalNumberOfMessages: number }
    >;
    loading: Record<LoadingTypes, boolean>;
}

const initialState: IFeedsState = {
    messagesByFeed: {},
    messagesByIdentifier: {},
    pendingMessagesByFeedId: {},
    loading: {
        messages: false,
        message: false,
    },
};

export const feedsSlice = createSlice({
    name: "feeds",
    initialState,
    reducers: {
        resetFeedsState: () => initialState,
        setMessages: (
            state,
            {
                payload: { topicId, messages, totalNumberOfMessages },
            }: PayloadAction<{
                topicId: number;
                messages: Message[];
                totalNumberOfMessages: number;
            }>,
        ) => {
            state.messagesByFeed[topicId] = {
                identifiers: messages.map((message) => {
                    state.messagesByIdentifier[message.messageIdentifier] =
                        message;

                    return message.messageIdentifier;
                }),
                totalNumberOfMessages,
            };
        },

        appendMessages: (
            state,
            {
                payload: { topicId, messages, totalNumberOfMessages, prepend },
            }: PayloadAction<{
                topicId: number;
                messages: Message[];
                totalNumberOfMessages: number;
                prepend?: boolean;
            }>,
        ) => {
            let newIdentifiers = messages.map((message) => {
                state.messagesByIdentifier[message.messageIdentifier] = message;

                return message.messageIdentifier;
            });

            if (!state.messagesByFeed[topicId]) {
                state.messagesByFeed[topicId] = {
                    identifiers: [],
                    totalNumberOfMessages,
                };
            }

            const currentMessageIdentifiers =
                state.messagesByFeed[topicId].identifiers;

            if (currentMessageIdentifiers.length > 0) {
                newIdentifiers = newIdentifiers.filter(
                    (identifier) =>
                        !currentMessageIdentifiers.includes(identifier),
                );
            }

            state.messagesByFeed[topicId] = {
                identifiers: prepend
                    ? [...newIdentifiers, ...currentMessageIdentifiers]
                    : [...currentMessageIdentifiers, ...newIdentifiers],
                totalNumberOfMessages,
            };
        },

        setMessage: (state, { payload }: PayloadAction<Message>) => {
            const payloadWithSerializeValues = {
                ...payload,
                topic: {
                    ...payload.topic,
                    id: payload.topic?.id.toString(),
                },
            };
            const storedMessage =
                state.messagesByIdentifier[
                    payloadWithSerializeValues.messageIdentifier
                ];
            state.messagesByIdentifier[
                payloadWithSerializeValues.messageIdentifier
            ] = {
                ...(payloadWithSerializeValues as unknown as Message),
                slowdowns:
                    payloadWithSerializeValues.slowdowns ??
                    storedMessage?.slowdowns,
            };

            if (
                payloadWithSerializeValues.topic.id &&
                !state.messagesByFeed[payloadWithSerializeValues.topic.id]
            ) {
                state.messagesByFeed[payloadWithSerializeValues.topic.id] = {
                    identifiers: [],
                    totalNumberOfMessages: 0,
                };
            }

            if (
                payloadWithSerializeValues.topic.id &&
                !state.messagesByFeed[
                    payloadWithSerializeValues.topic.id
                ].identifiers.includes(
                    payloadWithSerializeValues.messageIdentifier,
                )
            ) {
                state.messagesByFeed[
                    payloadWithSerializeValues.topic.id
                ].identifiers.unshift(
                    payloadWithSerializeValues.messageIdentifier,
                );
                state.messagesByFeed[
                    payloadWithSerializeValues.topic.id
                ].totalNumberOfMessages += 1;
            }
        },
        setNotFoundMessage: (
            state,
            { payload }: PayloadAction<{ messageIdentifier: Hex }>,
        ) => {
            state.messagesByIdentifier[payload.messageIdentifier] = null;
        },

        setPendingMessages: (
            state,
            {
                payload: { topicId, messageIdentifiers, totalNumberOfMessages },
            }: PayloadAction<{
                topicId: number;
                messageIdentifiers?: Hex[];
                totalNumberOfMessages?: number;
            }>,
        ) => {
            state.pendingMessagesByFeedId[topicId] = {
                messageIdentifiers: messageIdentifiers || [],
                totalNumberOfMessages: totalNumberOfMessages ?? 0,
            };
        },
        addPendingMessage: (
            state,
            {
                payload: { topicId, messageIdentifier, totalNumberOfMessages },
            }: PayloadAction<{
                topicId: number;
                messageIdentifier: Hex;
                totalNumberOfMessages: number;
            }>,
        ) => {
            const oldPendingMessageIdentifiers =
                state.pendingMessagesByFeedId[topicId]?.messageIdentifiers ||
                [];
            const listMessageIdentifiers =
                state.messagesByFeed[topicId].identifiers;

            let pendingMessageIdentifiers = oldPendingMessageIdentifiers;
            if (
                !oldPendingMessageIdentifiers.includes(messageIdentifier) &&
                !listMessageIdentifiers.includes(messageIdentifier)
            ) {
                pendingMessageIdentifiers = [
                    ...oldPendingMessageIdentifiers,
                    messageIdentifier,
                ];
            }

            state.pendingMessagesByFeedId[topicId] = {
                messageIdentifiers: pendingMessageIdentifiers,
                totalNumberOfMessages,
            };
        },
        setLoading: (
            state,
            {
                payload: { type, status },
            }: PayloadAction<{ type: LoadingTypes; status: boolean }>,
        ) => {
            state.loading[type] = status;
        },
    },
});

export const {
    appendMessages,
    setMessage,
    setNotFoundMessage,
    setMessages,
    setLoading,
    setPendingMessages,
    addPendingMessage,
    resetFeedsState,
} = feedsSlice.actions;

// All messages
function selectMessagesObject(topicId: number) {
    return function selectMessagesObjectFromState(state: RootState) {
        return state.feeds.messagesByFeed[topicId];
    };
}
export function selectNumberOfMessages(topicId: number) {
    return createSelector(selectMessagesObject(topicId), (topicIdMessages) => {
        return topicIdMessages?.totalNumberOfMessages || 0;
    });
}
export function selectMessagesIdentifiers(topicId: number) {
    return createSelector(selectMessagesObject(topicId), (topicIdMessages) => {
        return topicIdMessages?.identifiers || [];
    });
}

// One message
export function selectMessage(messageIdentifier: Hex) {
    return function selectMessageFromState(state: RootState) {
        return state.feeds.messagesByIdentifier[messageIdentifier];
    };
}

export function selectLoading(type: LoadingTypes) {
    return function selectLoadingFromState(state: RootState) {
        return state.feeds.loading[type];
    };
}

// Pending messages
export function selectPendingMessageIdentifierData(topicId: number) {
    return function selectPendingMessagesFromState(state: RootState) {
        return state.feeds.pendingMessagesByFeedId[topicId];
    };
}

export const getMessages =
    (topicId: number, skip = 0, take = TAKE_MESSAGES_AMOUNT) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoading({ type: "messages", status: true }));
        try {
            const { messages, totalCount } = await getMessagesFromBackend(
                topicId,
                skip,
                take,
            );

            if (skip === 0) {
                dispatch(
                    setMessages({
                        topicId: Number(topicId),
                        messages,
                        totalNumberOfMessages: totalCount,
                    }),
                );
            } else {
                dispatch(
                    appendMessages({
                        topicId: Number(topicId),
                        messages,
                        totalNumberOfMessages: totalCount,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading({ type: "messages", status: false }));
        }
    };

const enrichMessageData = async (
    murmurContract: MurmurContractType,
    message: Message,
) => {
    const messageData = await getMessageData(
        murmurContract,
        message.messageIdentifier,
    );
    let slowdowns;
    if (messageData) {
        slowdowns = messageData.slowdowns as Slowdown[];
    }

    return {
        ...message,
        slowdowns: slowdowns?.map(({ epochs, ...rest }) => ({
            epochs: Number(epochs),
            ...rest,
        })),
    };
};

export const getMessage =
    ({
        murmurContract,
        messageIdentifier,
        dispatchLoadingStatus = false,
        shouldEnrichIfNeeded = false,
        oldMessage,
    }: {
        murmurContract: MurmurContractType;
        messageIdentifier: Hex;
        dispatchLoadingStatus: boolean;
        shouldEnrichIfNeeded: boolean;
        oldMessage: Message;
    }) =>
    async (dispatch: AppDispatch) => {
        if (!messageIdentifier) {
            console.error(
                `Someone called getMessage with messageIdentifier: ${messageIdentifier}. Blocking that call`,
            );
            return;
        }
        if (dispatchLoadingStatus) {
            dispatch(setLoading({ type: "message", status: true }));
        }

        try {
            const message = await getMessageFromBackend(messageIdentifier);
            if (message === null) {
                dispatch(setNotFoundMessage({ messageIdentifier }));
                return null;
            }
            let messageToDispatch = message;
            if (
                shouldEnrichIfNeeded &&
                (!oldMessage ||
                    oldMessage.nextTurnDeadline !== message.nextTurnDeadline ||
                    oldMessage.revealedAt !== message.revealedAt)
            ) {
                const enrichedMessage = await enrichMessageData(
                    murmurContract,
                    message,
                );
                messageToDispatch = enrichedMessage;
            }
            dispatch(setMessage(messageToDispatch));

            return messageToDispatch;
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: "Could not get message",
                }),
            );
        } finally {
            if (dispatchLoadingStatus) {
                dispatch(setLoading({ type: "message", status: false }));
            }
        }
    };

export const setMessageRead =
    (message: Message) => async (dispatch: AppDispatch) => {
        try {
            await setMessageReadFromBackend(message.messageIdentifier);
            dispatch(setMessage({ ...message, isRead: true }));
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: "Could not set message read",
                }),
            );
        }
    };

export const showPendingMessages =
    (topicId: number) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
        const pendingMessagesPayload =
            selectPendingMessageIdentifierData(topicId)(getState());

        // Order from newer to older
        const messageIdentifiers = [
            ...pendingMessagesPayload.messageIdentifiers,
        ].reverse();

        try {
            const messages = [];
            for (let index = 0; index < messageIdentifiers.length; index++) {
                // Update message from backend to get latest info tailored for that user
                const message = await getMessageFromBackend(
                    messageIdentifiers[index],
                );
                if (message !== null) {
                    messages.push(message);
                }
            }

            dispatch(
                appendMessages({
                    ...pendingMessagesPayload,
                    topicId,
                    messages,
                    prepend: true,
                }),
            );
            // Reset state
            dispatch(
                setPendingMessages({
                    topicId,
                }),
            );
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: "Could not show pending messages",
                }),
            );
        }
    };

export default feedsSlice.reducer;
