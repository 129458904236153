import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { objectToSnake } from "ts-case-convert";

import { selectCurrentUserWalletAddress } from "src/slices/authSlice";

import { SocketConnectedContext } from "src/contexts";
import { socket } from "src/socket";

const useSocketUpdates = (
    room,
    onUpdate,
    fallbackOnUpdate,
    intervalTime = 10_000,
    extraConditionForSetup = true,
    socketEventIdentifier = room,
) => {
    const address = useSelector(selectCurrentUserWalletAddress);
    const isSocketConnected = useContext(SocketConnectedContext);

    useEffect(() => {
        if (isSocketConnected && address && extraConditionForSetup) {
            console.debug(
                `Setting up for socket connection on ${room}${
                    room !== socketEventIdentifier
                        ? `/event:${socketEventIdentifier}`
                        : ""
                } (socket: ${socket.connected})`,
            );
            socket.on(socketEventIdentifier, onUpdate);

            socket.timeout(5000).emit(
                "join",
                objectToSnake({
                    fromWallet: address,
                    room: room.toString(),
                }),
            );

            return () => {
                console.debug(
                    `Clearing up socket connection on ${room}${
                        room !== socketEventIdentifier
                            ? `/event:${socketEventIdentifier}`
                            : ""
                    }`,
                );
                socket.timeout(5000).emit(
                    "leave",
                    objectToSnake({
                        fromWallet: address,
                        room: room.toString(),
                    }),
                );
                socket.off(socketEventIdentifier, onUpdate);
            };
        }
    }, [
        isSocketConnected,
        extraConditionForSetup,
        onUpdate,
        room,
        socketEventIdentifier,
        address,
    ]);

    useEffect(() => {
        if (!isSocketConnected && fallbackOnUpdate && extraConditionForSetup) {
            const interval = setInterval(fallbackOnUpdate, intervalTime);

            return () => {
                clearInterval(interval);
            };
        }
    }, [
        isSocketConnected,
        extraConditionForSetup,
        fallbackOnUpdate,
        intervalTime,
    ]);
};

export default useSocketUpdates;
