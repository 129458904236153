import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { Container, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { objectToCamel } from "ts-case-convert";

import useCurrentPrivyAccount from "src/hooks/useCurrentPrivyAccount";
import useSocketUpdates from "src/hooks/useSocketUpdates";

import CurrencyDisplay from "src/components/CurrencyDisplay";

import EthereumIcon from "src/assets/ethereum.svg?react";
import { CURRENCY_SYMBOL, MAX_LOTTERY_PRIZE_DECIMALS } from "src/constants";
import { RewardsContext } from "src/contexts";

const DEFAULT_TRANSITION_PARAMS = {
    transitionTime: 0,
    opacity: 1,
};

export default function PointsAnimation() {
    const account = useCurrentPrivyAccount();
    const {
        points,
        wei,
        coupons,
        resetState,
        setPointsReward,
        setCouponsReward,
        setWeiReward,
    } = useContext(RewardsContext);
    const [{ transitionTime, opacity }, setTransitionParams] = useState(
        DEFAULT_TRANSITION_PARAMS,
    );

    const onPointsUpdate = useCallback(
        async (socketMessage) => {
            const { points, type, lotteryEventId, revenue } =
                objectToCamel(socketMessage);
            if (lotteryEventId) {
                setCouponsReward(points);
            } else {
                setPointsReward(points);
            }

            if (revenue) {
                setWeiReward(revenue);
            }
        },
        [setCouponsReward, setPointsReward, setWeiReward],
    );
    useSocketUpdates(
        `lottery_points_${account?.address}`,
        onPointsUpdate,
        null,
        5_000,
        account?.address,
    );

    useEffect(() => {
        function notificationDisplayHandler(event) {
            if (event?.notification?.additionalData?.amount) {
                const weiAmount = event?.notification?.additionalData?.amount;
                setWeiReward(weiAmount);
            }
        }
        OneSignal.Notifications.addEventListener(
            "foregroundWillDisplay",
            notificationDisplayHandler,
        );

        return () => {
            OneSignal.Notifications.removeEventListener(
                "foregroundWillDisplay",
                notificationDisplayHandler,
            );
        };
    }, [setWeiReward]);

    const animate = useCallback(() => {
        setTimeout(() => {
            // animate
            setTransitionParams({
                transitionTime: 0.5,
                opacity: 0,
            });

            // Reset
            setTimeout(() => {
                setTransitionParams(DEFAULT_TRANSITION_PARAMS);
                resetState();
            }, 500);
        }, 1_000);
    }, [resetState]);

    let label = "";
    if (points || wei || coupons) {
        label = (
            <>
                {points
                    ? `${points > 0 ? "+" : "-"}${points} pt${points > 1 || points < -1 ? "s" : ""}`
                    : null}
                {wei ? (
                    <>
                        &nbsp;+
                        <CurrencyDisplay
                            amount={wei || 0}
                            currency={CURRENCY_SYMBOL.ETH}
                            convertFrom={CURRENCY_SYMBOL.WEI}
                            toFixed={wei ? MAX_LOTTERY_PRIZE_DECIMALS : 0}
                            showUnit={false}
                        />
                        &nbsp;
                        <EthereumIcon
                            style={{
                                verticalAlign: "middle",
                            }}
                        />
                    </>
                ) : null}
                {coupons ? (
                    <>
                        &nbsp;+{coupons}&nbsp;
                        <LocalActivityIcon
                            style={{
                                verticalAlign: "middle",
                            }}
                        />
                    </>
                ) : null}
            </>
        );
    }

    useEffect(() => {
        if (label) {
            animate();
        }
    }, [label, animate]);

    if (!label) {
        return null;
    }

    const padding = `50px`;

    return (
        <Container
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                transition: `transform ${transitionTime}s ease-in-out, opacity ${transitionTime}s ease-in-out`,
                transform: `translateY(calc(50vh - ${padding}))`,
                opacity,
                zIndex: 9999,
            }}
        >
            <Typography
                sx={{
                    color: "white",
                    fontSize: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...(label
                        ? {
                              padding,
                              backgroundSize: `${2 * padding + 15}px auto`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundImage:
                                  "radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), transparent, transparent)",
                          }
                        : {}),
                }}
                className={`splash-target ${label ? "active" : ""}`}
            >
                {label}
            </Typography>
        </Container>
    );
}
