import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useIsLowBalance from "src/hooks/useIsLowBalance";
import useTLPsStatus from "src/hooks/useTLPsStatus";
import { getDraft, saveDraft } from "src/services/draftDB";
import { selectCurrentUserWalletAddress } from "src/slices/authSlice";

import {
    ChangeSpotLoadingContext,
    ContractsContext,
    DraftMessageContext,
    JustSubscribedContext,
    MESSAGE_VISIBILITY,
    MessagePaymentPendingContext,
    MessageRevealedContext,
    MessageStatusContext,
    NoFundsModalContext,
    RewardsContext,
    SocketConnectedContext,
    TLPWorkersContext,
    TooLateForMessageRevealContext,
} from "src/contexts";
import { MurmurTourProvider } from "src/contexts/MurmurTourContext";

export default function AppContextsProvider({
    children,
    isSocketConnected,
    contracts,
}) {
    const { isLowBalance } = useIsLowBalance();
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );
    const [messageStatus, setMessageStatus] = useState({
        visibility: MESSAGE_VISIBILITY.SECRET,
    });
    const [messagePaymentState, setMessagePaymentState] = useState({});
    const [rewardsState, setRewardsState] = useState({
        points: null,
        wei: null,
        coupons: null,
    });
    const setPointsReward = useCallback(
        (points) =>
            setRewardsState((prev) => ({
                ...prev,
                points,
            })),
        [setRewardsState],
    );
    const setWeiReward = useCallback(
        (wei) =>
            setRewardsState((prev) => ({
                ...prev,
                wei,
            })),
        [setRewardsState],
    );
    const setCouponsReward = useCallback(
        (coupons) =>
            setRewardsState((prev) => ({
                ...prev,
                coupons,
            })),
        [setRewardsState],
    );
    const resetState = useCallback(
        () =>
            setRewardsState({
                wei: null,
                coupons: null,
                points: null,
            }),
        [setRewardsState],
    );

    const rewardsCallbacks = {
        setPointsReward,
        setWeiReward,
        setCouponsReward,
        resetState,
    };

    const TLPWorkersContextValue = useTLPsStatus(currentUserWalletAddress);

    const [revealedMessage, setRevealedMessage] = useState(null);
    const [tooLateForMessageReveal, setTooLateForMessageReveal] =
        useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [changeSpotIsLoading, setChangeSpotIsLoading] = useState(false);
    const [draftMessage, setDraftMessage] = useState({});
    const updateDraftMessage = useCallback(
        async (draft) => {
            await saveDraft(draft, currentUserWalletAddress);
            setDraftMessage(draft);
        },
        [setDraftMessage, currentUserWalletAddress],
    );

    useEffect(() => {
        (async () => {
            if (currentUserWalletAddress) {
                const storedDraft = await getDraft(currentUserWalletAddress);
                setDraftMessage(storedDraft || {});
            }
        })();
    }, [currentUserWalletAddress]);

    const [noFundsModalOpen, setIsNoFundsModalOpen] = useState(false);
    const [storedOnFundedCallback, setStoredOnFundedCallback] = useState(false);
    const triggerOnlyIfFunds = useCallback(
        (callback) => {
            if (isLowBalance) {
                setIsNoFundsModalOpen(true);
                // see https://dev.to/sarioglu/how-to-store-a-function-using-react-usestate-4ffh for why
                setStoredOnFundedCallback(() => callback);
            } else {
                callback();
            }
        },
        [isLowBalance],
    );
    const onFunded = useCallback(() => {
        if (storedOnFundedCallback) {
            storedOnFundedCallback();
            setIsNoFundsModalOpen(false);
        }
        setStoredOnFundedCallback(null);
    }, [storedOnFundedCallback]);

    useEffect(() => {
        if (!isLowBalance) {
            onFunded();
        }
    }, [isLowBalance, onFunded]);

    return (
        <SocketConnectedContext.Provider value={isSocketConnected}>
            <ContractsContext.Provider value={contracts}>
                <TLPWorkersContext.Provider value={TLPWorkersContextValue}>
                    <MessageRevealedContext.Provider
                        value={{
                            revealedMessage,
                            setRevealedMessage,
                        }}
                    >
                        {/* WARNING: TLPWorkersContext.Provider and MessageRevealedContext.Provider must parents of MurmurTourProvider */}
                        <MurmurTourProvider>
                            <MessageStatusContext.Provider
                                value={{ messageStatus, setMessageStatus }}
                            >
                                <MessagePaymentPendingContext.Provider
                                    value={{
                                        setIsPendingPayment: (
                                            messageIdentifier,
                                            isPending,
                                        ) => {
                                            setMessagePaymentState({
                                                ...messagePaymentState,
                                                [messageIdentifier]: isPending,
                                            });
                                        },
                                        isPendingPayment: (messageIdentifier) =>
                                            Boolean(
                                                messagePaymentState[
                                                    messageIdentifier
                                                ],
                                            ),
                                    }}
                                >
                                    <TooLateForMessageRevealContext.Provider
                                        value={{
                                            tooLateForMessageReveal,
                                            setTooLateForMessageReveal,
                                        }}
                                    >
                                        <JustSubscribedContext.Provider
                                            value={{
                                                subscribed,
                                                setSubscribed,
                                            }}
                                        >
                                            <DraftMessageContext.Provider
                                                value={{
                                                    draftMessage,
                                                    updateDraftMessage,
                                                }}
                                            >
                                                <NoFundsModalContext.Provider
                                                    value={{
                                                        noFundsModalOpen,
                                                        setIsNoFundsModalOpen: (
                                                            open,
                                                        ) => {
                                                            if (!open) {
                                                                if (
                                                                    isLowBalance
                                                                ) {
                                                                    setStoredOnFundedCallback(
                                                                        null,
                                                                    );
                                                                } else {
                                                                    storedOnFundedCallback();
                                                                    setStoredOnFundedCallback(
                                                                        null,
                                                                    );
                                                                }
                                                            }
                                                            setIsNoFundsModalOpen(
                                                                open,
                                                            );
                                                        },
                                                        triggerOnlyIfFunds,
                                                        onFunded,
                                                    }}
                                                >
                                                    <ChangeSpotLoadingContext.Provider
                                                        value={{
                                                            changeSpotIsLoading,
                                                            setChangeSpotIsLoading,
                                                        }}
                                                    >
                                                        <RewardsContext.Provider
                                                            value={{
                                                                ...rewardsState,
                                                                ...rewardsCallbacks,
                                                            }}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDateFns
                                                                }
                                                            >
                                                                {children}
                                                            </LocalizationProvider>
                                                        </RewardsContext.Provider>
                                                    </ChangeSpotLoadingContext.Provider>
                                                </NoFundsModalContext.Provider>
                                            </DraftMessageContext.Provider>
                                        </JustSubscribedContext.Provider>
                                    </TooLateForMessageRevealContext.Provider>
                                </MessagePaymentPendingContext.Provider>
                            </MessageStatusContext.Provider>
                        </MurmurTourProvider>
                    </MessageRevealedContext.Provider>
                </TLPWorkersContext.Provider>
            </ContractsContext.Provider>
        </SocketConnectedContext.Provider>
    );
}
