/* global BigInt */
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { Address } from "viem";

import { getProfileFromBackend, getUserRevenue } from "src/services/api";

import { AppDispatch, RootState } from "src/store";
import { ProfileMetadata, RevenueData } from "src/types/api-interfaces";
import { EnhancedProfileData } from "src/types/extraTypes";

type ProfileBalance = {
    walletAddress: Address;
    amount: string;
};
type LoadingTypes = "profile" | "deposit" | "withdraw" | "revenue";

type ProfileWithOptionalMetadata = EnhancedProfileData &
    Partial<ProfileMetadata>;
export type Profile = ObjectToCamel<ProfileWithOptionalMetadata>;

interface IProfileState {
    profileByAddress: Record<Address, Profile>;
    revenueByAddress: Record<Address, ObjectToCamel<RevenueData>>;
    loading: Record<LoadingTypes, boolean>;
    balanceByAddress: Record<Address, ProfileBalance>;
}
const initialState: IProfileState = {
    profileByAddress: {},
    revenueByAddress: {},
    loading: {
        profile: false,
        deposit: false,
        withdraw: false,
        revenue: false,
    },
    balanceByAddress: {},
};
export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        resetProfileState: () => initialState,
        addProfileData: (
            state,
            {
                payload: { walletAddress, profile },
            }: PayloadAction<{ walletAddress: Address; profile: Profile }>,
        ) => {
            state.profileByAddress[walletAddress] = {
                // in order to keep data provided in auth query as we don't get them in other queries
                ...(state.profileByAddress[walletAddress] || {}),
                ...profile,
            };
        },
        setLoading: (
            state,
            {
                payload: { type, status },
            }: PayloadAction<{ type: LoadingTypes; status: boolean }>,
        ) => {
            state.loading[type] = status;
        },
        setBalanceByAddress: (
            state,
            { payload }: PayloadAction<ProfileBalance>,
        ) => {
            if (!state.balanceByAddress[payload.walletAddress]) {
                state.balanceByAddress[payload.walletAddress] = payload;
            }
            state.balanceByAddress[payload.walletAddress].amount =
                payload.amount ??
                state.balanceByAddress[payload.walletAddress].amount;
        },
        setRevenueData: (state, action) => {
            const { walletAddress, revenue } = action.payload;
            state.revenueByAddress[walletAddress] = revenue;
        },
        setSentFirstSecretPostToTrue: (state, action) => {
            const { walletAddress } = action.payload;
            const userProfile = state.profileByAddress[walletAddress];
            if (userProfile) {
                userProfile.hasSentFirstSecretPost = true;
            }
        },

        removeProfileData: (state, { payload: { walletAddress } }) => {
            delete state.profileByAddress[walletAddress];
        },
    },
});

export const {
    addProfileData,
    setLoading,
    setBalanceByAddress,
    setRevenueData,
    setSentFirstSecretPostToTrue,
    removeProfileData,
    resetProfileState,
} = profileSlice.actions;

export const getProfile =
    (walletAddress: Address) => async (dispatch: AppDispatch) => {
        dispatch(setLoading({ type: "profile", status: true }));

        try {
            const profile = await getProfileFromBackend(walletAddress);
            dispatch(addProfileData({ walletAddress, profile }));
        } catch (error: unknown) {
            const e = error as AxiosError;
            if (e.response?.status == 404) {
                console.warn(e);
            } else {
                console.error(e);
            }
        } finally {
            dispatch(setLoading({ type: "profile", status: false }));
        }
    };

export const getCurrentUserProfile =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentUserWalletAddress } = getState().auth;
        if (!currentUserWalletAddress) {
            console.error("No current user wallet address to fetch profile");
            dispatch(setLoading({ type: "profile", status: false }));
            return;
        }

        dispatch(getProfile(currentUserWalletAddress));
    };

export const fetchUserRevenue =
    () => async (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentUserWalletAddress } = getState().auth;
        if (!currentUserWalletAddress) {
            console.error("No current user wallet address to fetch revenue");
            dispatch(setLoading({ type: "revenue", status: false }));
            return;
        }

        dispatch(setLoading({ type: "revenue", status: true }));
        try {
            const revenueData = await getUserRevenue();

            dispatch(
                setRevenueData({
                    walletAddress: currentUserWalletAddress,
                    revenue: revenueData,
                }),
            );
        } catch (error) {
            console.error("Error fetching user revenue:", error);
        } finally {
            dispatch(setLoading({ type: "revenue", status: false }));
        }
    };

export const setHasSentFirstSecretPostToTrue =
    () => (dispatch: AppDispatch, getState: () => RootState) => {
        const { currentUserWalletAddress } = getState().auth;
        if (!currentUserWalletAddress) {
            console.error(
                "No current user wallet address to set hasSentFirstSecretPost",
            );
            return;
        }
        dispatch(
            setSentFirstSecretPostToTrue({
                walletAddress: currentUserWalletAddress,
            }),
        );
    };

export function selectProfile(walletAddress: Address) {
    return function selectProfileFromState(state: RootState) {
        return state.profiles.profileByAddress[walletAddress];
    };
}

export function selectCurrentUserProfile(state: RootState) {
    return state.auth.currentUserWalletAddress
        ? state.profiles.profileByAddress[state.auth.currentUserWalletAddress]
        : undefined;
}

export function selectLoading(type: LoadingTypes) {
    return function selectLoadingFromState(state: RootState) {
        return state.profiles.loading[type];
    };
}
export function selectAddressBalanceAmount(walletAddress: Address) {
    return function selectAddressBalanceFromState(state: RootState) {
        return BigInt(
            state.profiles.balanceByAddress[walletAddress]?.amount || 0,
        );
    };
}

export const selectUserSubscriptionRevenue = (state: RootState) => {
    let userRevenue;
    if (state.auth.currentUserWalletAddress) {
        userRevenue =
            state.profiles.revenueByAddress[
                state.auth.currentUserWalletAddress
            ];
    }
    return userRevenue ? userRevenue.subscriptionRevenueWei : 0;
};

export const selectUserSlowMessageRevenue = (state: RootState) => {
    let userRevenue;
    if (state.auth.currentUserWalletAddress) {
        userRevenue =
            state.profiles.revenueByAddress[
                state.auth.currentUserWalletAddress
            ];
    }
    return userRevenue ? userRevenue.slowMessageRevenueWei : 0;
};

export const selectUserReferralRevenue = (state: RootState) => {
    let userRevenue;
    if (state.auth.currentUserWalletAddress) {
        userRevenue =
            state.profiles.revenueByAddress[
                state.auth.currentUserWalletAddress
            ];
    }
    return userRevenue ? userRevenue.referralRevenueWei : 0;
};

export const selectUserBestHunterRevenue = (state: RootState) => {
    let userRevenue;
    if (state.auth.currentUserWalletAddress) {
        userRevenue =
            state.profiles.revenueByAddress[
                state.auth.currentUserWalletAddress
            ];
    }
    return userRevenue ? userRevenue.bestHunterRevenueWei : 0;
};

export const selectUserTotalRevenue = createSelector(
    [
        selectUserSubscriptionRevenue,
        selectUserSlowMessageRevenue,
        selectUserReferralRevenue,
        selectUserBestHunterRevenue,
    ],
    (
        subRevenueWei,
        slowdownRevenueWei,
        referralRevenueWei,
        bestHunterRevenueWei,
    ) =>
        (subRevenueWei ?? 0) +
        (slowdownRevenueWei ?? 0) +
        (referralRevenueWei ?? 0) +
        (bestHunterRevenueWei ?? 0),
);

export const selectUserHasSentFirstSecretPost = createSelector(
    [selectCurrentUserProfile],
    (currentUserProfile) => currentUserProfile?.hasSentFirstSecretPost,
);

export default profileSlice.reducer;
