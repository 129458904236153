import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePublicClient from "src/hooks/usePublicClient";
import {
    selectAddressBalanceAmount,
    setBalanceByAddress,
} from "src/slices/profileSlice";

import { DEFAULT_PROFILE_BALANCE } from "src/constants";
import { MurmurTourContext } from "src/contexts/MurmurTourContext";

export default function useBalance({
    address,
    chainId,
    fetchInterval = null, // interval value if need to fetch the data periodically
}) {
    const dispatch = useDispatch();
    const publicClient = usePublicClient(chainId);
    const balance = useSelector(selectAddressBalanceAmount(address));
    const { murmurTourState } = useContext(MurmurTourContext);

    const fetchBalance = useCallback(
        async (address) => {
            try {
                const balance = await publicClient.getBalance({ address });
                dispatch(
                    setBalanceByAddress({
                        walletAddress: address,
                        amount: balance.toString(),
                    }),
                );
                return balance;
            } catch (e) {
                console.error(`Cannot get balance from ${address}`, e);
            }
        },
        [dispatch, publicClient],
    );

    useEffect(() => {
        if (address && !murmurTourState?.isTourActive) {
            fetchBalance(address);
            let interval;
            if (fetchInterval) {
                interval = setInterval(
                    () => fetchBalance(address),
                    fetchInterval,
                );
            }
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        } else {
            console.warn("No address provided, not doing anything");
        }
    }, [address, fetchBalance, fetchInterval, murmurTourState?.isTourActive]);

    return {
        balance: murmurTourState?.isTourActive
            ? DEFAULT_PROFILE_BALANCE
            : balance,
        refetch: () => fetchBalance(address),
    };
}
