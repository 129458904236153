import { Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useGenerateKeyPairIfNeeded from "src/hooks/useGenerateKeyPairIfNeeded";
import { selectCurrentUserWalletAddress } from "src/slices/authSlice";
import { selectContractSettings } from "src/slices/contractSettingsSlice";

import ChangeSpotForm from "src/components/ChangeSpotForm";
import CurrencyDisplay from "src/components/CurrencyDisplay";

import { CURRENCY_SYMBOL, ROUTES } from "src/constants";
import { findFirstAvailableBurningRateAndPosition } from "src/utils";

export default function JoinFeedForm({ sortedSubscriptions, topicId }) {
    const navigate = useNavigate();

    const { burningRateStep, subscriptionFee } = useSelector(
        selectContractSettings,
    );
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );

    const generateKeyPairIfNeeded = useGenerateKeyPairIfNeeded({
        walletAddress: currentUserWalletAddress,
    });
    let sortedTakenBurningRates = sortedSubscriptions.map(
        (sub) => sub.burningRate,
    );

    let [minEthForJoining, position] = findFirstAvailableBurningRateAndPosition(
        sortedTakenBurningRates.reverse(), // We want the burning rates in ascending order for findFirstAvailableBurningRateAndPosition
        burningRateStep,
    );

    return (
        <Stack sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center" gap={1} py={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography color="secondary">
                        Your position in the queue
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    flex={1}
                >
                    {position} out of {(sortedSubscriptions?.length ?? 0) + 1}
                </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" alignItems="center" gap={1} py={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography color="secondary">Subscription fee</Typography>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    flex={1}
                >
                    <CurrencyDisplay
                        amount={subscriptionFee}
                        currency={CURRENCY_SYMBOL.ETH}
                        convertFrom={CURRENCY_SYMBOL.WEI}
                        toFixed={4}
                    />
                </Stack>
            </Stack>
            <Divider />
            <ChangeSpotForm
                currentUserIsSubscriber={false}
                topicId={topicId}
                generateKeyPairIfNeeded={generateKeyPairIfNeeded}
                minETHValue={minEthForJoining}
                shouldShowSliderAndPrices={false}
                onSubmit={() => {
                    navigate(ROUTES.HOME);
                }}
            />
        </Stack>
    );
}
