import { Typography } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useTopicId from "src/hooks/useTopicId";
import {
    getCurrentUserSubscription,
    getFeedSubscriptions,
    selectCurrentUserSubscriptionTo,
    selectSortedFeedSubscriptions,
} from "src/slices/subscriptionsSlice";

import JoinFeedForm from "src/components/JoinFeedForm";
import Page from "src/components/Page";

import { DEMO_TOUR, ROUTES } from "src/constants";
import { ContractsContext } from "src/contexts";

export default function FeedJoinPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const topicId = useTopicId();
    const { murmurContract } = useContext(ContractsContext);
    const sortedFeedSubscriptions = useSelector(
        selectSortedFeedSubscriptions(topicId),
    );
    const currentUserSubscription = useSelector(
        selectCurrentUserSubscriptionTo(topicId),
    );

    const initialLoad = useRef();
    const subscriptionLoad = useRef();

    useEffect(() => {
        if (
            murmurContract &&
            !initialLoad.current &&
            topicId &&
            Number(topicId) !== DEMO_TOUR.TOPIC.id
        ) {
            initialLoad.current = true;
            dispatch(getFeedSubscriptions(topicId));
            dispatch(getCurrentUserSubscription(murmurContract, topicId));
        }
    }, [topicId, murmurContract, dispatch]);

    useEffect(() => {
        if (
            typeof currentUserSubscription != "undefined" &&
            !subscriptionLoad.current
        ) {
            subscriptionLoad.current = true;
            if (currentUserSubscription) {
                navigate(ROUTES.CHANGE_SPOT);
            }
        }
    }, [currentUserSubscription, navigate, topicId]);

    return (
        <Page sx={{ padding: 0 }}>
            <Typography
                sx={{
                    p: 2,
                    fontWeight: "fontWeightMedium",
                }}
            >
                Joining the queue guarantees you access to exclusive information
                before anyone else.
            </Typography>
            <JoinFeedForm
                sortedSubscriptions={sortedFeedSubscriptions}
                topicId={topicId}
            />
        </Page>
    );
}
