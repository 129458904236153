/* global BigInt, */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import {
    getCurrentLotteryPoints as getCurrentLotteryPointsFromBackend,
    getLatestLottery as getLatestLotteryFromBackend,
} from "src/services/api";

import { AppDispatch, RootState } from "src/store";
import { LotteryEvent } from "src/types/api-interfaces";

interface ILotteriesState {
    latest: ObjectToCamel<LotteryEvent> | null;
    points: number | null;
}

export const lotteriesSlice = createSlice({
    name: "lotteries",
    initialState: {
        latest: null,
    } as ILotteriesState,
    reducers: {
        setCurrentLottery: (
            state,
            {
                payload,
            }: PayloadAction<{
                latestLottery: ObjectToCamel<LotteryEvent> | null;
            }>,
        ) => {
            state.latest = payload.latestLottery;
        },
        setPoints: (
            state,
            { payload }: PayloadAction<{ points: number | null }>,
        ) => {
            state.points = payload.points;
        },
    },
});

export const { setCurrentLottery, setPoints } = lotteriesSlice.actions;

export const getLatestLottery = () => async (dispatch: AppDispatch) => {
    const latestLottery = await getLatestLotteryFromBackend();
    dispatch(
        setCurrentLottery({
            latestLottery,
        }),
    );
};

export const getCurrentLotteryPoints = () => async (dispatch: AppDispatch) => {
    const { points } = await getCurrentLotteryPointsFromBackend();
    dispatch(
        setPoints({
            points,
        }),
    );
};

export const selectLatestLottery = () => {
    return function selectLatestLotteryFromState(state: RootState) {
        return state.lotteries.latest;
    };
};

export const selectIsLotteryOngoing = () => {
    return function selectLatestLotteryFromState(state: RootState) {
        const latestLottery = state.lotteries.latest;
        const lotteryIsOngoing =
            latestLottery &&
            new Date(latestLottery.startDate).getTime() <= Date.now() &&
            new Date(latestLottery.endDate).getTime() >= Date.now();

        return lotteryIsOngoing;
    };
};

export const selectLotteryPoints = () => {
    return function selectLotteryPointsFromState(state: RootState) {
        return state.lotteries.points;
    };
};

export default lotteriesSlice.reducer;
