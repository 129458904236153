import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useIsCurrentUser from "src/hooks/useIsCurrentUser";
import { getProfile, selectProfile } from "src/slices/profileSlice";

import Profile from "src/components/Profile";

export default function TopicParticipantRow({
    address,
    profile = null,
    spot,
    rightArea,
    disabled = false,
    sx,
    ...props
}) {
    const dispatch = useDispatch();

    const profileFetched = useSelector(selectProfile(address));
    const profileToDisplay = profile || profileFetched;
    const isYou = useIsCurrentUser(profileToDisplay?.walletAddress);

    useEffect(() => {
        if (address && !profile && !profileFetched) {
            dispatch(getProfile(address));
        }
    }, [dispatch, address, profile, profileFetched]);

    return (
        <Profile
            {...props}
            profile={profileToDisplay}
            avatarSize={25}
            labelSuffix={isYou ? " (You)" : ""}
            leftArea={
                <Typography
                    sx={{
                        fontSize: "inherit",
                        fontWeight: "inherit",
                        color: "secondary.main",
                        mr: 0.5,
                    }}
                >
                    {spot || ""}
                </Typography>
            }
            labelProps={{
                sx: {
                    fontSize: "inherit",
                    fontWeight: "inherit",
                    color: disabled ? "secondary.main" : "white.main",
                },
            }}
            rightArea={rightArea}
            sx={{
                px: 2,
                py: 1,
                fontWeight: 500,
                fontSize: 12,
                backgroundColor: isYou ? "primary.dark" : undefined,
                flex: 1,
                ...sx,
            }}
            addSocialLinkOnLabel
        />
    );
}
