import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectIsConnected, selectIsLoadingAuth } from "src/slices/authSlice";
import { selectCurrentUserProfile } from "src/slices/profileSlice";

import AvatarWithBadge from "src/components/AvatarWithBadge";

import { ROUTES } from "src/constants";

export default function CurrentUserAvatar({ profile, size = 25, className }) {
    const navigate = useNavigate();

    const profileFromStore = useSelector(selectCurrentUserProfile);

    const isConnected = useSelector(selectIsConnected);
    const isLoading = useSelector(selectIsLoadingAuth);

    const profileToDisplay = profile ?? profileFromStore;
    const displayUnauthenticatedAvatar = !isLoading && !isConnected;
    const displayAvatar = !isLoading && isConnected;

    return (
        <>
            {isLoading && <CircularProgress size={size} />}
            {displayAvatar && (
                <AvatarWithBadge
                    profile={profileToDisplay}
                    avatarSize={size}
                    className={className}
                />
            )}
            {displayUnauthenticatedAvatar && (
                <AvatarWithBadge
                    avatarSize={size}
                    onClick={() => navigate(ROUTES.START)}
                    className={className}
                />
            )}
        </>
    );
}
