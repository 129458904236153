/* global BigInt, */
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { v4 as uuidv4 } from "uuid";
import { Address, Hex, encodePacked, keccak256, pad, toHex } from "viem";

import {
    readFeedSubscriptions,
    sendMessageTLP,
    uploadAttachments,
} from "src/services/api";

import {
    LocalStorageKeys,
    TIMELOCK_ITERATIONS,
    ZERO_BYTES_32,
} from "src/constants";
import { encryptFile, forgeBytesToHex } from "src/cryptography/common";
import {
    getPrivateKey,
    getTimeLockPuzzleBytes,
} from "src/cryptography/timelockPuzzle";
import { encryptKey, encryptMessage } from "src/cryptography/walletEncryption";
import { getStorageValue, setStorageValue } from "src/localStorage";
import { PostMessageDataRequestArgs } from "src/types/api-interfaces";
import { MurmurContractType } from "src/types/murmurContractType";
import { generateJsonFile } from "src/utils";

export async function getEpochTimeFromContract(
    murmurContract: MurmurContractType,
) {
    const epochTime = await murmurContract.read.epochTime();
    return epochTime;
}

export async function getEpochCostFromContract(
    murmurContract: MurmurContractType,
) {
    const epochCost = await murmurContract.read.epochCost();
    return epochCost;
}

export async function getPermilFeeFromContract(
    murmurContract: MurmurContractType,
) {
    const protocolFeePermil = await murmurContract.read.protocolFeePermil();
    return protocolFeePermil;
}

export async function getSubscriptionFeeFromContract(
    murmurContract: MurmurContractType,
) {
    const subscriptionFee = await murmurContract.read.subscriptionFee();
    return subscriptionFee;
}

export async function getCooldownPeriodFromContract(
    murmurContract: MurmurContractType,
) {
    const cooldownPeriod = await murmurContract.read.cooldownPeriod();
    return cooldownPeriod;
}

export function getMessageIdentifier(
    murmurContract: MurmurContractType,
    topicId: number,
    message: string,
) {
    // CAREFUL! in hex, numbers are the same as string until 10 (a in hex)
    // So we need to be sure to encode the topicId as a number to match the logic
    // done on reveal in the contract!
    const topicIdWellFormed = Number.isInteger(topicId)
        ? pad(toHex(topicId))
        : pad(toHex(Number(topicId)));

    const messageIdentifier = keccak256(
        encodePacked(
            ["bytes32", "bytes32", "bytes32"],
            [
                pad(murmurContract.address.toLowerCase() as Address),
                topicIdWellFormed,
                keccak256(toHex(message)),
            ],
        ),
    );

    return messageIdentifier;
}

type DraftMessage = {
    text: string;
    attachments?: File[];
};
type PostMessage = {
    text: string;
    version: number;
    attachments?: Array<{
        filename: string;
        privateKey: string;
    }>;
};
export async function sendMessage(
    murmurContract: MurmurContractType,
    topicId: number,
    messageObj: DraftMessage,
    senderAddress: Address,
    referrerAddress: Address,
    keepPrivate: boolean = false,
    publicRevealDate: string | null = null,
) {
    const postMessage: PostMessage = {
        text: messageObj.text?.trim(),
        version: 1,
    };
    const formData = new FormData();

    if (messageObj.attachments && messageObj.attachments.length) {
        postMessage.attachments = [];
        for (const attachment of messageObj.attachments) {
            const atachmentPrivateKey = await getPrivateKey();
            const encryptedAttachment = await encryptFile(
                attachment,
                atachmentPrivateKey,
            );
            const identifier = uuidv4();
            const filename = `${identifier}.json`;

            const encryptedAttachmentFile = await generateJsonFile(
                encryptedAttachment,
                filename,
            );

            formData.append("attachments", encryptedAttachmentFile, filename);

            postMessage.attachments.push({
                filename,
                privateKey: forgeBytesToHex(atachmentPrivateKey),
            });
        }
    }

    const message = JSON.stringify(postMessage);

    const messageIdentifier = getMessageIdentifier(
        murmurContract,
        topicId,
        message,
    );
    const localMessages = getStorageValue<Record<Address, string>>(
        LocalStorageKeys.MESSAGES_MAP,
        {},
        senderAddress,
    );
    localMessages[messageIdentifier] = message;
    setStorageValue(
        LocalStorageKeys.MESSAGES_MAP,
        localMessages,
        senderAddress,
    );

    const expectedTime = import.meta.env.VITE_TIMELOCK_TIME_IN_SECONDS;

    let tlpData;
    let tlpProofHash;

    if (!keepPrivate) {
        let timeLockedKeyBytes;
        let timeLockPuzzleModulusBytes;
        let timeLockPuzzleBaseBytes;
        let ciphertextBytes;
        let ivBytes;

        [
            timeLockedKeyBytes,
            timeLockPuzzleModulusBytes,
            timeLockPuzzleBaseBytes,
            ciphertextBytes,
            ivBytes,
            tlpProofHash,
        ] = await getTimeLockPuzzleBytes(message, TIMELOCK_ITERATIONS);

        tlpData = {
            timeLockedKeyBytes,
            timeLockPuzzleModulusBytes,
            timeLockPuzzleBaseBytes,
            ciphertextBytes,
            ivBytes,
            expectedTime,
            iterations: TIMELOCK_ITERATIONS,
        };
    } else {
        tlpProofHash = ZERO_BYTES_32;
        tlpData = {};
    }

    let { subscriptions: recipientsSubscriptions } =
        await readFeedSubscriptions(topicId, 0, "all");

    // sort by decreasing burning rate
    // and remove message sender's subscription if applicable
    recipientsSubscriptions.sort(
        (a, b) => Number(b.burningRate) - Number(a.burningRate),
    );
    recipientsSubscriptions = recipientsSubscriptions.filter(
        (subscription) =>
            subscription.subscriber.toLowerCase() !==
            senderAddress.toLowerCase(),
    );

    const {
        addressToBurningRate,
        addressToPublicKey,
        messageRecipientsAddresses,
        messageRecipientsBurningRates,
    } = recipientsSubscriptions.reduce(
        (
            obj: {
                addressToBurningRate: Record<Address, number>;
                addressToPublicKey: Record<Address, string>;
                messageRecipientsAddresses: Address[];
                messageRecipientsBurningRates: bigint[];
            },
            subscription,
        ) => {
            if (Number(subscription.burningRate) > 0) {
                obj.addressToBurningRate[subscription.subscriber] =
                    subscription.burningRate;
                if (subscription.profile.publicKey) {
                    obj.addressToPublicKey[subscription.subscriber] =
                        subscription.profile.publicKey;
                }

                obj.messageRecipientsAddresses.push(subscription.subscriber);
                obj.messageRecipientsBurningRates.push(
                    BigInt(subscription.burningRate),
                );
            }

            return obj;
        },
        {
            addressToBurningRate: {},
            addressToPublicKey: {},
            messageRecipientsAddresses: [],
            messageRecipientsBurningRates: [],
        },
    );
    const { ciphertext, rawKey } = await encryptMessage(message);
    const encryptedMessage = {
        serializedEncryption: JSON.stringify(ciphertext),
    };
    const encryptedKeys = await Promise.all(
        messageRecipientsAddresses.map(async (walletAddress) => {
            if (!addressToPublicKey[walletAddress]) {
                console.warn(`Public key for address ${walletAddress} is null`);
                return null;
            }

            return {
                walletAddress,
                serializedEncryptedKey: JSON.stringify(
                    await encryptKey(
                        rawKey.key,
                        rawKey.iv,
                        addressToPublicKey[walletAddress],
                    ),
                ),
                burningRate: addressToBurningRate[walletAddress],
            };
        }),
    );

    const validEncryptedKeys = encryptedKeys.filter(
        (message) => message !== null,
    ) as ObjectToCamel<PostMessageDataRequestArgs>["encryptedKeys"];

    await sendMessageTLP({
        messageIdentifier,
        tlpData,
        tlpProofHash,
        encryptedMessage,
        encryptedKeys: validEncryptedKeys,
        topicId,
        publicRevealDate,
    });
    if (messageObj.attachments && messageObj.attachments.length) {
        await uploadAttachments(messageIdentifier, formData);
    }

    let tx;
    const batchSize = import.meta.env.VITE_SEND_MESSAGE_BATCH_SIZE;

    if (messageRecipientsBurningRates < batchSize) {
        tx = await murmurContract.write.startMessageDelivery([
            messageIdentifier,
            BigInt(topicId),
            tlpProofHash as Hex,
            messageRecipientsBurningRates,
            messageRecipientsAddresses,
            true,
            referrerAddress,
        ]);
    } else {
        await murmurContract.write.startMessageDelivery(
            [
                messageIdentifier,
                BigInt(topicId),
                tlpProofHash as Hex,
                messageRecipientsBurningRates.slice(0, batchSize),
                messageRecipientsAddresses.slice(0, batchSize),
                false,
                referrerAddress,
            ],
            {},
        );
        let idx = batchSize;
        while (idx < messageRecipientsBurningRates.length) {
            const endIdx = Math.min(
                idx + batchSize,
                messageRecipientsBurningRates.length,
            );

            tx = await murmurContract.write.sendMessageDeliveryBatch([
                messageIdentifier,
                messageRecipientsBurningRates.slice(idx, endIdx),
                messageRecipientsAddresses.slice(idx, endIdx),
            ]);

            idx += batchSize;
        }

        const emittedMessageExists =
            await murmurContract.read.messageByIdentifierExists([
                messageIdentifier,
            ]);
        if (!emittedMessageExists) {
            throw Error(
                "Inconsistent state after batching: message was not emitted.",
            );
        }
    }

    return [messageIdentifier, tx];
}

export async function joinFeedFromContract(
    murmurContract: MurmurContractType,
    topicId: number,
    burningRate: number,
) {
    const subscriptionFee =
        await getSubscriptionFeeFromContract(murmurContract);
    const tx = await murmurContract.write.joinFeed(
        [BigInt(topicId), BigInt(burningRate)],
        { value: BigInt(subscriptionFee) },
    );
    return tx;
}

export async function getSubscriptionFromContract(
    murmurContract: MurmurContractType,
    topicId: number,
    subscriberAddress: Address,
) {
    try {
        const sub = await murmurContract.read.getSubscription([
            BigInt(topicId),
            subscriberAddress,
        ]);

        return {
            ...sub,
            topicId,
            burningRate: Number(sub.burningRate),
        };
    } catch (e) {
        console.warn(
            "Could not get subscription for",
            topicId,
            subscriberAddress,
        );
        return null;
    }
}

export async function getSubscriptionsFromContract(
    murmurContract: MurmurContractType,
    subscriberAddress: Address,
) {
    const [feeds, subs] = await murmurContract.read.getSubscriptions([
        subscriberAddress,
        0n,
        10n,
    ]);

    const subsToReturn = subs.map((sub, i) => ({
        ...sub,
        topicId: Number(feeds[i]),
        burningRate: Number(sub.burningRate),
    }));

    return subsToReturn;
}

export async function getFeedSubscriptionsFromContract(
    murmurContract: MurmurContractType,
    topicId: number,
) {
    const [subs, totalNumberOfSubs] =
        await murmurContract.read.getSubscriptionsFromFeed([
            BigInt(topicId),
            0n,
            10n,
        ]);

    const subsToReturn = subs.map((sub) => ({
        ...sub,
        topicId,
        burningRate: Number(sub.burningRate),
    }));

    return [subsToReturn, Number(totalNumberOfSubs)];
}

export async function getMessageData(
    murmurContract: MurmurContractType,
    messageIdentifier: Hex,
) {
    try {
        const messageData = await murmurContract.read.messageData([
            messageIdentifier,
        ]);

        if (!messageData) {
            throw new Error(
                `Message ${messageIdentifier} has no data on-chain???`,
            );
        }
        return messageData;
    } catch (error) {
        console.error("Error fetching message:", error);
    }
}

export async function getCurrentMessageReader(
    murmurContract: MurmurContractType,
    messageIdentifier: Hex,
) {
    const currentReader = await murmurContract.read.getCurrentMessageReader([
        messageIdentifier,
    ]);

    return currentReader;
}

export async function setBurningRateInContract(
    murmurContract: MurmurContractType,
    topicId: number,
    burningRate: number,
) {
    await murmurContract.write.setBurningRate([
        BigInt(topicId),
        BigInt(burningRate),
    ]);
}

export async function slowMessagePropagationInContract(
    murmurContract: MurmurContractType,
    messageIdentifier: Hex,
    epochs: number,
    price: number,
) {
    await murmurContract.write.slowMessagePropagation(
        [messageIdentifier, BigInt(epochs)],
        { value: BigInt(price) },
    );
}

export async function leaveFeed(
    murmurContract: MurmurContractType,
    topicId: number,
) {
    await murmurContract.write.leaveFeed([BigInt(topicId)]);
}

export async function payForPrivateMessageReveal(
    murmurContract: MurmurContractType,
    messageIdentifier: Hex,
    amount: number,
) {
    return await murmurContract.write.payForPrivateMessageReveal(
        [messageIdentifier],
        { value: BigInt(amount) },
    );
}

export async function getPaymentDelayDuration(
    murmurContract: MurmurContractType,
) {
    const paymentDelayDuration =
        await murmurContract.read.paymentDelayDuration();

    return paymentDelayDuration;
}

export async function getSlowdownDelayDuration(
    murmurContract: MurmurContractType,
) {
    const slowdownDelayDuration =
        await murmurContract.read.slowdownDelayDuration();

    return slowdownDelayDuration;
}

export async function isCooldownBlockingSenderToSendMessage(
    murmurContract: MurmurContractType,
    topicId: number,
    accountAddress: Address,
) {
    const isElapsed =
        await murmurContract.read.isCooldownBlockingSenderToSendMessage([
            BigInt(topicId),
            accountAddress,
        ]);

    return isElapsed;
}

export async function getBurningRateStepFromContract(
    murmurContract: MurmurContractType,
) {
    try {
        const burningRateStep = await murmurContract.read.burningRateStep();
        return burningRateStep;
    } catch (error) {
        console.error("Error fetching burningRateStep:", error);
    }
}

export async function getEffectiveBurningRateForMessage(
    murmurContract: MurmurContractType,
    subscriberAddress: Address,
    messageIdentifier: Hex,
) {
    const messageEffectiveBurningRate =
        await murmurContract.read.getEffectiveBurningRateForMessage([
            subscriberAddress,
            messageIdentifier,
        ]);
    return messageEffectiveBurningRate;
}
