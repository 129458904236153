import { useContext } from "react";

import { DEMO_TOUR } from "src/constants";
import { MurmurTourContext } from "src/contexts/MurmurTourContext";

export default function useTopicId() {
    const { murmurTourState } = useContext(MurmurTourContext);
    return murmurTourState?.isTourActive
        ? DEMO_TOUR.TOPIC.id
        : Number(import.meta.env.VITE_DEFAULT_TOPIC_ID);
}
