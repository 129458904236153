import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { Address } from "viem";

import {
    getTopicFromBackend,
    getTopicPublisherStatusFromBackend,
    getTopicsFromBackend,
} from "src/services/api";
import { Profile, addProfileData } from "src/slices/profileSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { AppDispatch, RootState } from "src/store";
import { GetTopicsResponse, Topic } from "src/types/api-interfaces";

type LoadingTypes = "topics" | "publishers";

export type PublisherStatus = {
    score: number | null;
};
interface ITopicsState {
    topicsIds: number[];
    topicsById: Record<string, ObjectToCamel<Topic>>;
    totalPublishersByTopicId: Record<string, number>;
    publisherStatusesByTopicId: Record<
        string,
        Record<string, boolean | undefined | PublisherStatus>
    >;
    subscribedTopicsIds: number[];
    loading: Record<LoadingTypes | string, boolean>;
    total: number;
    totalSubscribedTopics: number;
}

const initialState: ITopicsState = {
    topicsIds: [],
    topicsById: {},
    totalPublishersByTopicId: {},
    publisherStatusesByTopicId: {}, // if false, it's not a topic publisher, if publisherStatusesByTopicId[topicId]?.[accountAddress] = undefined, then we don't know and need to fetch the data
    subscribedTopicsIds: [],
    loading: {
        topics: false,
        publishers: false,
    },
    total: 0,
    totalSubscribedTopics: 0,
};
export const topicsSlice = createSlice({
    name: "topics",
    initialState,
    reducers: {
        resetTopicsState: () => initialState,
        setTopic: (state, { payload }) => {
            state.topicsById[payload.id] = payload;
        },
        setTopics: (
            state,
            {
                payload,
            }: PayloadAction<
                ObjectToCamel<GetTopicsResponse> & {
                    refresh: boolean;
                }
            >,
        ) => {
            if (payload.refresh) {
                state.topicsIds = payload.topics.map((topic) => {
                    state.topicsById[topic.id] = topic;

                    return topic.id;
                });
                state.total = payload.total;
            } else {
                const newTopicIds = payload.topics.map((topic) => {
                    state.topicsById[topic.id] = topic;

                    return topic.id;
                });
                state.topicsIds = [...state.topicsIds, ...newTopicIds];
            }
        },
        setSubscribedTopics: (
            state,
            {
                payload,
            }: PayloadAction<
                ObjectToCamel<GetTopicsResponse> & { refresh: boolean }
            >,
        ) => {
            if (payload.refresh) {
                state.subscribedTopicsIds = payload.topics.map((topic) => {
                    state.topicsById[topic.id] = topic;

                    return topic.id;
                });
                state.totalSubscribedTopics = payload.total;
            } else {
                const newTopicIds = payload.topics.map((topic) => {
                    state.topicsById[topic.id] = topic;

                    return topic.id;
                });
                state.subscribedTopicsIds = [
                    ...state.subscribedTopicsIds,
                    ...newTopicIds,
                ];
            }
        },
        setTopicPublisherStatus: (
            state,
            {
                payload,
            }: PayloadAction<{
                accountAddress: Address;
                isTopicPublisher: boolean;
                topicId: number;
                score?: number | null;
            }>,
        ) => {
            if (!state.publisherStatusesByTopicId[payload.topicId]) {
                state.publisherStatusesByTopicId[payload.topicId] = {};
            }
            state.publisherStatusesByTopicId[payload.topicId] = {
                ...state.publisherStatusesByTopicId[payload.topicId],
                [payload.accountAddress]: payload.isTopicPublisher
                    ? {
                          score: payload.score ?? null,
                      }
                    : false,
            };
        },
        setTopicPublisherStatuses: (
            state,
            {
                payload,
            }: PayloadAction<{
                topicId: number;
                totalPublishers: number;
                publishers: Array<
                    PublisherStatus & {
                        accountAddress: Address;
                    }
                >;
            }>,
        ) => {
            state.publisherStatusesByTopicId[payload.topicId] =
                payload.publishers.reduce(
                    (acc, publisher) => ({
                        ...acc,
                        [publisher.accountAddress]: {
                            score: publisher.score,
                        },
                    }),
                    state.publisherStatusesByTopicId[payload.topicId],
                );
            state.totalPublishersByTopicId[payload.topicId] =
                payload.totalPublishers;
        },
        setLoading: (
            state,
            {
                payload,
            }: PayloadAction<{ type: LoadingTypes | string; status: boolean }>,
        ) => {
            state.loading[payload.type] = payload.status;
        },
    },
});

export const {
    setTopic,
    setTopics,
    setSubscribedTopics,
    setTopicPublisherStatus,
    setTopicPublisherStatuses,
    setLoading,
    resetTopicsState,
} = topicsSlice.actions;

function selectTopicsState(state: RootState) {
    return state.topics;
}

export function selectTopic(topicId: number) {
    return createSelector(
        selectTopicsState,
        (topicsState) => topicsState.topicsById[topicId],
    );
}

export function selectTopics() {
    return createSelector(selectTopicsState, (topicsState) => {
        return (
            topicsState.topicsIds.map(
                (topicId) => topicsState.topicsById[topicId],
            ) || []
        );
    });
}

export function selectTotalTopics() {
    return createSelector(
        selectTopicsState,
        (topicsState) => topicsState.total,
    );
}

export function selectSubscribedTopics() {
    return createSelector(selectTopicsState, (topicsState) => {
        return (
            topicsState.subscribedTopicsIds.map(
                (topicId) => topicsState.topicsById[topicId],
            ) || []
        );
    });
}

export function selectTotalSubscribedTopics() {
    return createSelector(
        selectTopicsState,
        (topicsState) => topicsState.totalSubscribedTopics,
    );
}

function selectTopicPublishersState(state: RootState) {
    return state.topics.publisherStatusesByTopicId;
}

export function selectTopicPublishers(topicId: number) {
    return createSelector(
        selectTopicPublishersState,
        (publisherStatusesByTopicId) =>
            Object.entries(publisherStatusesByTopicId[topicId] || {})
                .map(([accountAddress, publisherData]) => {
                    if (publisherData) {
                        const data = publisherData as PublisherStatus;
                        return {
                            accountAddress,
                            score: data.score,
                        };
                    }
                    return null;
                })
                .filter(Boolean)
                .sort(
                    (a, b) => (a?.score && b?.score && b.score - a.score) ?? 0,
                ), // order by score desc
    );
}

export function selectTopicTotalPublishers(topicId: number) {
    return (state: RootState) =>
        state.topics.totalPublishersByTopicId[topicId] || 0;
}

export function selectPublisherStatusByTopic(
    topicId: number,
    accountAddress?: Address,
) {
    return createSelector(
        selectTopicPublishersState,
        (publisherStatusesByTopicId) =>
            accountAddress
                ? publisherStatusesByTopicId[topicId]?.[accountAddress]
                : undefined,
    );
}

export function selectLoading(type = "topics") {
    return createSelector(
        selectTopicsState,
        (topicsState) => topicsState.loading[type],
    );
}

export const getTopics =
    (skip: number, take: number, refresh = false) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoading({ type: "topics", status: true }));
        try {
            const data = await getTopicsFromBackend(skip, take);
            dispatch(setTopics({ ...data, refresh }));
        } catch (e: unknown) {
            const error = e as AxiosError;
            console.error(error);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error.message,
                }),
            );
        } finally {
            dispatch(setLoading({ type: "topics", status: false }));
        }
    };

export const getSubscribedTopics =
    (skip: number, take: number, subscribedAddress: Address, refresh = false) =>
    async (dispatch: AppDispatch) => {
        dispatch(setLoading({ type: "subscribedTopics", status: true }));
        try {
            const data = await getTopicsFromBackend(
                skip,
                take,
                null,
                subscribedAddress,
            );
            dispatch(setSubscribedTopics({ ...data, refresh }));
        } catch (e: unknown) {
            const error = e as AxiosError;
            console.error(error);
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message: error.message,
                }),
            );
        } finally {
            dispatch(setLoading({ type: "subscribedTopics", status: false }));
        }
    };

export const getTopic = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setLoading({ type: "topics", status: true }));

    try {
        const data = await getTopicFromBackend(id);
        dispatch(setTopic(data));
    } catch (e: unknown) {
        const error = e as AxiosError;
        console.error(error);
        dispatch(
            setSnackbarFeedback({
                type: SeverityType.ERROR,
                message: error.message,
            }),
        );
    } finally {
        dispatch(setLoading({ type: "topics", status: false }));
    }
};

export const getTopicPublisherStatus =
    (topicId: number, accountAddress: Address) =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await getTopicPublisherStatusFromBackend(
                topicId,
                accountAddress,
            );
            if (response !== false) {
                const { score, ...publisher } = response;
                dispatch(
                    setTopicPublisherStatus({
                        accountAddress,
                        isTopicPublisher: true,
                        topicId,
                        score,
                    }),
                );
                dispatch(
                    addProfileData({
                        walletAddress: publisher.walletAddress,
                        profile: publisher as Profile,
                    }),
                );
            } else {
                dispatch(
                    setTopicPublisherStatus({
                        accountAddress,
                        topicId: topicId,
                        isTopicPublisher: false,
                    }),
                );
            }
        } catch (e) {
            console.error(e);
        }
    };

export default topicsSlice.reducer;
