import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPushNotificationStatus } from "src/services/api";
import {
    getNotificationStatus,
    selectCurrentAccountNotificationStatus,
    selectLoading,
    setCurrentAccountNotificationStatus,
} from "src/slices/notificationsSlice";
import { setSnackbarFeedback } from "src/slices/snackbarFeedbackSlice";

import { DEMO_TOUR } from "src/constants";

export default function useNotificationToggle({ notificationType, topicId }) {
    const dispatch = useDispatch();
    const notificationStatus = useSelector(
        selectCurrentAccountNotificationStatus(notificationType, topicId),
    );
    const loadingNotificationStatusFetching = useSelector(
        selectLoading(notificationType),
    );

    const [
        loadingNotificationStatusWriting,
        setLoadingNotificationStatusWriting,
    ] = useState(false);

    useEffect(() => {
        if (
            topicId &&
            Number(topicId) !== DEMO_TOUR.TOPIC.id &&
            notificationType
        ) {
            dispatch(getNotificationStatus(notificationType, topicId));
        }
    }, [dispatch, notificationType, topicId]);

    const toggle = async (e) => {
        try {
            setLoadingNotificationStatusWriting(true);
            const toggledValue = !notificationStatus;
            // optmistically sets the state to avoid slow feedback
            // if the following request fails, then we set it back
            dispatch(
                setCurrentAccountNotificationStatus({
                    notificationType,
                    topicId,
                    status: toggledValue,
                }),
            );
            await setPushNotificationStatus(
                topicId,
                notificationType,
                toggledValue,
            );
        } catch (e) {
            console.error(e);
            dispatch(
                setCurrentAccountNotificationStatus({
                    notificationType,
                    topicId,
                    status: notificationStatus,
                }),
            );
            dispatch(
                setSnackbarFeedback({
                    type: "error",
                    message: `Could not ${
                        notificationStatus ? "deactivate" : "activate"
                    } alert`,
                }),
            );
        } finally {
            setLoadingNotificationStatusWriting(false);
        }
    };

    return {
        loading:
            loadingNotificationStatusFetching ||
            loadingNotificationStatusWriting,
        status: notificationStatus,
        toggle,
    };
}
