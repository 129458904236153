import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTopicId from "src/hooks/useTopicId";
import { deleteKickedOutSubscriptionFromBackend } from "src/services/api";
import { selectCurrentUserWalletAddress } from "src/slices/authSlice";
import {
    getCurrentUserPreviousKickedOutSubscriptionFromBackend,
    selectCurrentUserPreviousKickedOutSubscription,
} from "src/slices/subscriptionsSlice";

import SkipButton from "src/components/SkipButton";
import StyledModal from "src/components/StyledModal";

import { DEMO_TOUR } from "src/constants";

export default function SubscriberKickedOutModal() {
    const topicId = useTopicId();

    const dispatch = useDispatch();

    const userPreviousKickedOutSubscription = useSelector(
        selectCurrentUserPreviousKickedOutSubscription(topicId),
    );
    const currentUserWalletAddress = useSelector(
        selectCurrentUserWalletAddress,
    );

    const handleClose = async () => {
        try {
            await deleteKickedOutSubscriptionFromBackend(
                topicId,
                currentUserWalletAddress,
            );
        } catch (e) {
            console.error("Error deleting kicked out subscription", e);
        } finally {
            // Update the state to close the modal if the kicked out subscription was deleted successfully
            dispatch(
                getCurrentUserPreviousKickedOutSubscriptionFromBackend(topicId),
            );
        }
    };

    useEffect(() => {
        if (topicId && Number(topicId) !== DEMO_TOUR.TOPIC.id) {
            dispatch(
                getCurrentUserPreviousKickedOutSubscriptionFromBackend(topicId),
            );
        }
    }, [topicId, dispatch]);

    return (
        <StyledModal
            open={userPreviousKickedOutSubscription != null}
            onClose={handleClose}
            aria-labelledby="subscriber-kicked-out-label"
            aria-describedby="subscriber-kicked-out-description"
        >
            <Stack gap={1}>
                <Typography
                    variant="h6"
                    align="center"
                    id="subscriber-kicked-out-description"
                >
                    You've been kicked out of the queue because you were
                    inactive
                </Typography>
                <SkipButton onClick={handleClose} />
            </Stack>
        </StyledModal>
    );
}
